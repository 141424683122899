import { useContext } from 'react';
import { ConnectionProviderContext } from '../../services/ConnectionProvider/ConnectionProvider';

const OfflineBanner = () => {
    const { isOnline } = useContext(ConnectionProviderContext);
    return (
        <></>
    )
}

export default OfflineBanner