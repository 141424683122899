import React from 'react';
import { ICompany, IProject, ICategory, IOwner } from '../../services/PropertiesProvider/PropertiesProvider';

interface IEmptyOption {
    id: number | null,
    title: string,
    children: IOwner[]
}
interface IModalSelect {
    id: string,
    label: string,
    defaultValue: string,
    selectedValue: ICompany | IProject | ICategory | IOwner | IEmptyOption | null,
    setSelectedValue: React.Dispatch<React.SetStateAction<ICompany | IProject | ICategory | IOwner | IEmptyOption | null>>,
    items: ICompany[] | IProject[] | ICategory[] | IOwner[],
    suggestions?: ICompany[] | IProject[] | ICategory[] | IOwner[],
}

export const emptyOption = {
    title: '- Ingen -',
    id: null,
    children: []
}

const ModalSelect = ({ id, label, defaultValue, items, selectedValue, setSelectedValue, suggestions }: IModalSelect) => {
    const isOwner = id === 'owner';

    const isProject = id === 'project';

    let labelClass = 'label label--2 label--semibold';

    if (isOwner || isProject) {
        labelClass += ' optional-label';
    }

    const selectItems = (isOwner || isProject ? [emptyOption, ...items] : items)
        .map((item) => {
            return <li key={item.id}>
                <a className='dropdown-item'
                    href='#'
                    onClick={(e) => {
                        e.preventDefault();
                        setSelectedValue(item);
                    }}>
                    {item.title}
                </a>
            </li>
        });


    const toggleFocus = () => {
        document.getElementById(`${id}-search`)?.focus();
    }

    return (
        <div className="input-group dropdown dropdown--searchable" onClick={toggleFocus}>
            <p className={labelClass}>
                {label}

                {isOwner || isProject ? <span>Valgfritt</span> : null}
            </p>
            <button
                className="dropdown-toggle"
                type="button"
                id="company-dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-offset="0,4">
                {selectedValue && selectedValue.title || defaultValue}
            </button>
            {isOwner ? <p className='label label--2 input-info'>
                Faktura sendes beboer
            </p> : null}
            <div className="dropdown-menu" aria-labelledby={`${id}-dropdown`}>
                <div className="dropdown__search">
                    <span className="icon icon--search"></span>
                    <input type="text" placeholder="Søk her..." id={`${id}-search`} />
                </div>
                <ul>
                    {selectItems}
                </ul>
            </div>
            {id !== 'project' && id !== 'owner' ?
                <div className="shortcuts" data-input={`${id}-dropdown`}>
                    {
                        suggestions?.map((suggestion, idx) => {
                            return suggestion && suggestion.title ? <button type="button"
                                className="shortcuts__item"
                                onClick={(e) => {
                                    setSelectedValue(suggestion);
                                }}
                                key={idx}>
                                {suggestion && suggestion.title}
                            </button> : null
                        })
                    }
                </div> : null}
        </div>
    )
}

export default ModalSelect