import { useState, useContext, useEffect } from 'react';
import React from 'react';
import { AuthProviderContext } from '../../services/AuthProvider/AuthProvider';
import { Navigate, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form";
import LoginCode from '../LoginCode/LoginCode';
import { sendVerificationCode, SUCCESS } from '../../services/ApiProvider/ApiProvider';
import { ToastContainer, toast } from 'react-toastify';

const isValidEmail = (email: string) => {
    const provider = email.split('@')[1];
    return provider && provider === 'obos.no' || provider === 'bob.no'
}

const Login = () => {
    const { isLogged } = useContext(AuthProviderContext);
    const [userEmail, setUserEmail] = useState<string>('');
    const [hashCode, setHashCode] = useState<string>('');
    const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isCodeValidation, setIsCodeValidation] = useState<boolean>(false);
    const [loginRemember, setLoginRemember] = useState<boolean>(false);
    const [error, setError] = useState(false);
    const { register, watch, handleSubmit, setValue } = useForm();
    const watchEmail = watch('email');
    let inputClass = '';

    useEffect(() => {
        if (watchEmail !== undefined && watchEmail.trim() !== '') {
            setError(false);
            return setButtonDisabled(false);
        }

        setButtonDisabled(true);
    }, [watchEmail]);

    if (isLogged) {
        return <Navigate to="/" />
    }

    const onSubmit = async (form: any) => {

        if (!isValidEmail(form.email)) {
            // setValue('email', '');
            setError(true);
            return;
        }

        setButtonDisabled(false);
        setIsLoading(true);

        const responseEmailVerification = await sendVerificationCode(form.email);

        if (responseEmailVerification.status === SUCCESS) {
            setHashCode(responseEmailVerification.data);
            setUserEmail(form.email);
            setLoginRemember(form.loginRemember);
            setIsCodeValidation(true);
        }
    }

    let buttonClass = 'btn btn--filled btn--full';

    if (buttonDisabled) {
        buttonClass += ' disabled';
    }

    if (isLoading) {
        buttonClass += ' btn--loading';
    }

    if (error) {
        inputClass = 'error';
    }

    if (isCodeValidation) {
        return <LoginCode email={userEmail} hashCode={hashCode} loginRemember={loginRemember} />
    }

    return (
        <>
            <div className="page-content">
                <section className='login'>
                    <div className='container'>
                        <div className="row">
                            <div className="col-md-6 offset-md-3">
                                <h1 className="title title--h1">Logg inn</h1>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="input-group">
                                        <label htmlFor="loginEmail">E-post</label>
                                        <input type="email"
                                            id="loginEmail"
                                            className={inputClass}
                                            {...register('email')} />
                                        {error ? <p className='input-error'>
                                            Sjekk at e-posten er riktig
                                        </p> : ''}
                                        <div className="login__checkbox">
                                            <input id="loginRemember"
                                                className="custom-checkbox"
                                                type="checkbox"
                                                {...register('loginRemember')}
                                            />
                                            <label htmlFor="loginRemember">Husk innloggingen</label>
                                        </div>
                                        <button className={buttonClass} type='submit'>Neste</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>

    )
}

export default Login 