import ContentLoader from 'react-content-loader';

const OverviewSkeleton = () => {
    return (
        <ContentLoader
            speed={2}
            viewBox="0 0 476 750"
            backgroundColor='#ffff'>
            <rect x="5" y="6" rx="3" ry="3" width="542" height="30" />
            <rect x="5" y="45" rx="3" ry="3" width="542" height="85" />
            <rect x="5" y="140" rx="3" ry="3" width="542" height="85" />
        </ContentLoader>
    )
}

export default OverviewSkeleton