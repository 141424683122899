import { useContext } from 'react';
import { AuthProviderContext } from '../../services/AuthProvider/AuthProvider';
import { Navigate, redirect } from 'react-router-dom';

interface IPageRedirect {
    Page: React.FC
}

const PageRedirect = ({ Page }: IPageRedirect) => {
    const { isLogged } = useContext(AuthProviderContext);

    if (isLogged) {
        return <Page />
    }

    return <Navigate to='/login' />
}

export default PageRedirect