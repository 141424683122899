import { Routes, Route, useLocation } from "react-router-dom";
import Home from "../../pages/Home/Home";
import Login from '../../pages/Login/Login';
import Overview from '../../pages/Overview/Overview';
import Profile from '../../pages/Profile/Profile';
import { useContext, useEffect } from 'react';
import { AuthProviderContext } from '../../services/AuthProvider/AuthProvider';
import PageRedirect from "../../pages/PageRedirect/PageRedirect";
import LoginCode from "../../pages/LoginCode/LoginCode";
import Sync from '../../pages/Sync/Sync';

export default function RootRouter() {
    const location = useLocation();

    useEffect(() => {
        if (document.getElementsByClassName('modal-backdrop')[0]) {
            document.getElementsByClassName('modal-backdrop')[0].remove();
        }
    }, [location]);

    return (
        <Routes>
            <Route path="/" element={<PageRedirect Page={Home} />} />
            <Route path="/overview" element={<PageRedirect Page={Overview} />} />
            <Route path="/sync" element={<PageRedirect Page={Sync} />} />
            <Route path="/profile" element={<PageRedirect Page={Profile} />} />
            <Route path="/login" element={<Login />} />
        </Routes>
    )
}