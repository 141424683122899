import BottomBar from '../../components/BottomBar/BottomBar';
import { useState, useContext, useEffect } from 'react';
import { PropertiesProviderContext } from '../../services/PropertiesProvider/PropertiesProvider';
import { ConnectionProviderContext } from '../../services/ConnectionProvider/ConnectionProvider';
import OfflineOverview from '../../components/OfflineOverview/OfflineOverview';
import { ping, NETWORK_ERROR, SUCCESS } from '../../services/ApiProvider/ApiProvider';

const Sync = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSync, setIsSync] = useState(false);
    const { throwGetProperties } = useContext(PropertiesProviderContext);
    const { isOnline, setIsOnline } = useContext(ConnectionProviderContext);
    let loadingClass = '';
    let text = `Har du nettverksproblemer og/eller opplever at data ikke er oppdatert, kan du trykke her`;

    const handleSubmitButton = async () => {
        setIsLoading(true);
        const getPropertiesResponse = await throwGetProperties();
        setIsLoading(false);
        if (getPropertiesResponse) {
            setIsSync(true);
        }
    }

    if (isLoading) {
        loadingClass = 'btn--loading';
    }

    const throwPing = async () => {
        try {
            const pingResponse = await ping();

            if (pingResponse.status === SUCCESS) {
                return setIsOnline(true);
            }
        }

        catch (e: any) {
            console.log(e);
            if (e.code === NETWORK_ERROR) {
                setIsOnline(false)
            }
        }

    }

    useEffect(() => {
        throwPing();
        setIsSync(false);
    }, []);

    if (isSync) {
        text = 'Data er nå synkronisert.';
    }

    return (
        <main className="page-content">
            <header className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="label label--medium label--4 page-header__title">Synkroniser</h1>
                        </div>
                    </div>
                </div>
            </header>

            {isOnline ? <div className="sync-data">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 offset-md-4 col-sm-8 offset-sm-2">
                            <p className="body-text body-text--3">
                                {text}
                            </p>
                            {
                                isSync ? null : <button id="syncDataButton"
                                    onClick={handleSubmitButton}
                                    className={`btn btn--icon-left sync-data__button ${loadingClass}`}>
                                    <span className="icon icon--refresh"></span>
                                    Synkroniser data
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div> : <OfflineOverview />}
            <BottomBar />
        </main>
    )
}

export default Sync