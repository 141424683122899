import { createContext, useState, useContext, useEffect } from 'react';
import React from 'react';
import { AuthProviderContext, getStorage } from '../AuthProvider/AuthProvider';
import { getProperties } from '../ApiProvider/ApiProvider';
import { resetExternalLibs } from '../Utils/Utils';

export interface IOwner {
    id: number | null,
    title: string,
    children: IOwner[],
    parentId?: number
}
export interface ICompany {
    id: number | null,
    title: string,
    children: IOwner[],
    parentId?: number
}

export interface ICategory {
    id: number | null,
    title: string,
    children: IOwner[]
}

export interface IProject {
    id: number | null,
    title: string,
    children: IOwner[],
    parentId?: number
}

export enum PROPERTY_KEYS {
    Companies = 'OHT_COMPANIES',
    Categories = 'OHT_CATEGORIES',
    Projects = 'OHT_PROJECTS',
    Owners = 'OHT_OWNERS'
}

export enum PROPERTY_HASHS {
    Companies = 'OHT_COMPANIES_HASH',
    Categories = 'OHT_CATEGORIES_HASH',
    Projects = 'OHT_PROJECTS_HASH',
    Owners = 'OHT_OWNERS_HASH'
}

interface IPropertiesProvider {
    companies: ICompany[],
    categories: ICategory[],
    projects: IProject[],
    owners: IOwner[],
    findCompany: Function,
    findCategory: Function,
    findProject: Function,
    findFilteredProject: Function,
    findOwner: Function,
    throwGetProperties: Function
}

export const PropertiesProviderContext = createContext<IPropertiesProvider>({
    companies: [],
    categories: [],
    projects: [],
    owners: [],
    findCompany: () => null,
    findCategory: () => null,
    findProject: () => null,
    findFilteredProject: () => null,
    findOwner: () => null,
    throwGetProperties: () => null
});

const getCachedProperty = (key: PROPERTY_KEYS.Categories | PROPERTY_KEYS.Companies | PROPERTY_KEYS.Projects | PROPERTY_KEYS.Owners) => {
    const storage = getStorage();

    return storage.getItem(key) &&
        JSON.parse(storage.getItem(key) || '[]') || [];
}

const savePropertyCache = (key: PROPERTY_KEYS.Categories | PROPERTY_KEYS.Companies | PROPERTY_KEYS.Projects | PROPERTY_KEYS.Owners,
    value: string) => {
    getStorage().setItem(key, value);
}

export const PropertiesProvider = ({ children }: { children: any }) => {
    const { isLogged, setIsLogged } = useContext(AuthProviderContext)
    const [companies, setCompanies] = useState<ICompany[]>(getCachedProperty(PROPERTY_KEYS.Companies));
    const [categories, setCategories] = useState<ICategory[]>(getCachedProperty(PROPERTY_KEYS.Categories));
    const [projects, setProjects] = useState<IProject[]>(getCachedProperty(PROPERTY_KEYS.Projects));
    const [owners, setOwners] = useState<IOwner[]>(getCachedProperty(PROPERTY_KEYS.Owners));
    const ONE_MINUTE = 1000 * 60;

    const throwGetProperties = async () => {
        const responseProperties = await getProperties();
        const [companiesList, categoriesList, projectsList, ownersList] = responseProperties;

        if (companiesList) {
            setCompanies(companiesList);
            savePropertyCache(PROPERTY_KEYS.Companies, JSON.stringify(companiesList || []));
        }

        if (categoriesList) {
            setCategories(categoriesList);
            savePropertyCache(PROPERTY_KEYS.Categories, JSON.stringify(categoriesList || []));
        }

        if (projectsList) {
            setProjects(projectsList);
            savePropertyCache(PROPERTY_KEYS.Projects, JSON.stringify(projectsList || []));
        }

        if (ownersList) {
            setOwners(ownersList);
            savePropertyCache(PROPERTY_KEYS.Owners, JSON.stringify(ownersList || []));
        }

        resetExternalLibs();

        return true;
    }

    const findCompany = (companyId: string) => {
        return companies.find(company => company.id === parseInt(companyId));
    }

    const findCategory = (categoryId: string) => {
        return categories.find(category => category.id === parseInt(categoryId));
    }

    const findProject = (projectId: string) => {
        return projects.find(project => project.id === parseInt(projectId));
    }

    const findFilteredProject = (projectId: string, filteredProjects: IProject[]) => {
      return filteredProjects.find(project => project.id === parseInt(projectId));
    }

    const findOwner = (ownerId: string, filteredOwners: IOwner[]) => {
        return filteredOwners.find(owner => owner.id === parseInt(ownerId));
    }

    useEffect(() => {
        if (isLogged) {
            throwGetProperties();
        }

        const intervalId = setInterval(async () => {
            try {
                await throwGetProperties();
            } catch (e: any) {
                console.log(e);
            }

        }, ONE_MINUTE);

        return () => clearInterval(intervalId);
    }, []);

    return <PropertiesProviderContext.Provider
        value={{
            companies, categories, projects, owners,
            findCompany, findCategory, findProject, findOwner, findFilteredProject,
            throwGetProperties
        }}>
        {children}
    </PropertiesProviderContext.Provider>

}