import { useContext } from 'react';
import { DaysProviderContext } from '../../services/DaysProvider/DaysProvider';
import { addDays, subDays } from 'date-fns/esm';
import { format, startOfWeek, endOfWeek } from 'date-fns';

export const WeekSlider = () => {
    const { selectedWeek, setSelectedWeek, setSelectedDay } = useContext(DaysProviderContext);
    const firstWeekDay = startOfWeek(selectedWeek, { weekStartsOn: 1 })
    const lastWeekDay = endOfWeek(selectedWeek, { weekStartsOn: 1 });

    const formatWeekDay = (weekDay: Date) => {
        return format(weekDay, "d'.'LLL").toLowerCase();
    }

    const moveWeek = (option: string) => {
        const change = option === 'previous' ? -7 : 7;
        const newWeek = addDays(selectedWeek, change);
        setSelectedWeek(newWeek);
        setSelectedDay(newWeek);
    }

    return (
        <div className="week-slider">
            <p className="label label--4">{formatWeekDay(firstWeekDay)} - {formatWeekDay(lastWeekDay)}
                , {format(selectedWeek, 'yyyy')}</p>
            <div className="arrows">
                <button aria-label="Previous week"
                    onClick={() => { moveWeek('previous') }}>
                    <span className="icon icon--arrow-left"></span>
                </button>
                <button aria-label="Next week"
                    onClick={() => { moveWeek('next') }}>
                    <span className="icon icon--arrow-right"></span>
                </button>
            </div>
        </div>
    )
}