const TabEmpty = () => {
    return (
        <div className="hour-list__empty">
            <figure>
                <img src="/assets/images/splash-illustration.svg" alt="" />
            </figure>
            <h2 className="label label--semibold label--5">Ingen timer registrert i dag</h2>
            <p className="label label--3 text-gray-70">Klikk på (+) ikonet for å føre timene dine</p>
        </div>
    )
}

export default TabEmpty