import WeekDay from '../WeekDay/WeekDay';
import { IWeekDay } from '../../services/DaysProvider/DaysProvider';
import { format } from 'date-fns';

const WeekDays = ({ children }: { children: IWeekDay[] }) => {

    const days = children.map((day, idx) => {
        return <WeekDay {...day} key={idx} />
    });

    return (
        <ul className="week-bar__day-selection" role="tablist">
            {days}
        </ul>
    )
}

export default WeekDays