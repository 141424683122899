import { useContext, useState } from 'react';
import { DaysProviderContext } from '../../services/DaysProvider/DaysProvider';
import { format } from 'date-fns';
import { sendWorklogsConfirmation, SUCCESS } from '../../services/ApiProvider/ApiProvider';

const OverviewSubmitModal = () => {
    const { selectedWeek, postId, setPostId } = useContext(DaysProviderContext);
    const [isLoading, setIsLoading] = useState(false);

    let loadingClass = '';

    const handleSubmit = async () => {
        setIsLoading(true);
        const month = format(selectedWeek, 'MM');
        const year = format(selectedWeek, 'yyyy');
        
        const responseSubmit = await sendWorklogsConfirmation(parseInt(year), parseInt(month));
        setIsLoading(false);
        
        if (responseSubmit === SUCCESS) {
            setPostId(postId + 1);
            return document.getElementById('submit-overview-close')?.click();
        }

        // TODO show error
    }

    if (isLoading) {
        loadingClass = 'btn--loading';
    }

    return (
        <div className="modal fade modal--centered" id="submitHourModal" tabIndex={-1} aria-labelledby="bottom-bar-add" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <header className="modal-header">
                        <button className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            id="submit-overview-close">
                            <span className="icon icon--sm icon--close"></span>
                        </button>
                    </header>
                    <div className="modal-body">
                        <h3 className="title title--h3">Ønsker du å sende inn timene dine?</h3>
                        <p className="body-text body-text--3">
                            Du vil nå sende inn alle registrerte timer fram til i dag. 
                            Etter innsending kan du ikke endre disse i OnTime. 
                            Eventuelle feilrettinger må gjøres i OnProperty.
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn--outline" data-bs-dismiss="modal">Avbryt</button>
                        <button className={`btn ${loadingClass}`} id="submitHourButton"
                            onClick={handleSubmit}>Send inn</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OverviewSubmitModal