interface IBlockAddMessage {
    header: string,
    text: string
}

const BlockedAddMessage = ({header, text}: IBlockAddMessage) => {
    return (
        <div className="modal fade modal--centered" id="addHourDrawer"
            tabIndex={-1}
            aria-labelledby="bottom-bar-add" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <header className="modal-header">
                        <button className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            id="blocked-add-close">
                            <span className="icon icon--sm icon--close"></span>
                        </button>
                    </header>
                    <div className="modal-body">
                        <h3 className="title title--h3">{header}</h3>
                        <p className="body-text body-text--3">
                            {text}
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button className="btn" data-bs-dismiss="modal">Lukk</button>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlockedAddMessage