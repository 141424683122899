import React, { createContext, useState } from 'react';
import { LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_EXPIRATION_KEY, LOCAL_STORAGE_USER_EMAIL } from '../ApiProvider/ApiProvider';
import { isAfter } from 'date-fns';
import axios from 'axios';

export const LOGIN_REMEMBER = 'OHT_SESSION_REMEMBER';

export const getStorage = () => {
    return (sessionStorage.getItem(LOGIN_REMEMBER) === 'true' ? sessionStorage : localStorage);
}

type IAuthProviderContext = {
    isLogged: boolean,
    setIsLogged: React.Dispatch<React.SetStateAction<boolean>>,
    email?: string,
    setEmail: React.Dispatch<React.SetStateAction<string>>,
    token: string,
    setToken: React.Dispatch<React.SetStateAction<string>>,
    expiration: Date,
    setExpiration: React.Dispatch<React.SetStateAction<Date>>,
    logout: Function,
    isObosUser: boolean
}

export const isLoginExpired = () => {
    let localStorageTokenExpiration = getStorage().getItem(LOCAL_STORAGE_EXPIRATION_KEY);

    return (localStorageTokenExpiration &&
        isAfter(new Date(), new Date(localStorageTokenExpiration))) ||
        !getStorage().getItem(LOCAL_STORAGE_TOKEN_KEY)
}

export const removeLocalStorageValues = () => {
    const storage = getStorage();

    storage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
    storage.removeItem(LOCAL_STORAGE_EXPIRATION_KEY);
    storage.removeItem(LOCAL_STORAGE_USER_EMAIL);
    
    return true;
}

export const AuthProviderContext = createContext<IAuthProviderContext>({
    isLogged: false,
    setIsLogged: () => null,
    email: '',
    setEmail: () => null,
    token: '',
    setToken: () => null,
    expiration: new Date(),
    setExpiration: () => null,
    logout: () => null,
    isObosUser: false
});

const isObosEmail = (email: string) => {
    if (!email) {
        return false;
    }

    const splittedEmail = email.split('@');

    if (splittedEmail.length < 2) {
        return false;
    }

    return splittedEmail[1] === 'obos.no';
}

export const AuthProvider = ({ children }: { children: any }) => {
    if (isLoginExpired()) {
        removeLocalStorageValues();
    }

    const storage = getStorage();

    const localStorageToken = storage.getItem(LOCAL_STORAGE_TOKEN_KEY);
    const localStorageTokenExpiration = storage.getItem(LOCAL_STORAGE_EXPIRATION_KEY);
    const localStorageEmail = storage.getItem(LOCAL_STORAGE_USER_EMAIL);

    const [isLogged, setIsLogged] = useState<boolean>(localStorageToken ? true : false);
    const [token, setToken] = useState(localStorageToken ? localStorageToken : '');
    const [email, setEmail] = useState(localStorageEmail ? localStorageEmail : '');

    const isObosUser = isObosEmail(email);

    const [expiration, setExpiration] = useState<Date>(
        localStorageTokenExpiration ? new Date(localStorageTokenExpiration) : new Date());

    if (token) {
        axios.defaults.headers.common['Authorization'] =
            `Bearer ${token}`;
    }

    const logout = () => {
        removeLocalStorageValues();
        setToken('');
        setEmail('');
        setIsLogged(false);
    }

    return <AuthProviderContext.Provider
        value={{
            isLogged, setIsLogged,
            email, setEmail,
            token, setToken,
            expiration, setExpiration,
            logout, isObosUser
        }}>
        {children}
    </AuthProviderContext.Provider>
}