import ContentLoader from 'react-content-loader';
import WorklogContainer from '../WorklogContainer/WorklogContainer';

const HomepageSkeleton = () => {
    return (
        <WorklogContainer>
            <ContentLoader
                speed={2}
                viewBox="0 0 476 700"
                backgroundColor='#ffff'>
                <rect x="5" y="6" rx="2" ry="2" width="212" height="15" />
                <rect x="5" y="30" rx="2" ry="2" width="542" height="68" />
                <rect x="5" y="110" rx="2" ry="2" width="542" height="68" />
            </ContentLoader>
        </WorklogContainer>
    )
}

export default HomepageSkeleton