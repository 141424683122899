import BottomBar from "../../components/BottomBar/BottomBar"
import { useContext } from 'react';
import { AuthProviderContext } from '../../services/AuthProvider/AuthProvider';
import EditEmailDrawer from "../../components/EditEmailDrawer/EditEmailDrawer";

const Profile = () => {

    const { email, logout } = useContext(AuthProviderContext);

    const handleLogoutButton = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        logout();
    }

    return (
        <main className="page-content">
            <header className="page-header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="label label--medium label--4 page-header__title">Min profil</h1>
                        </div>
                    </div>
                </div>
            </header>

            <div className="profile-list">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <ul className="profile-list__list">
                                <li className="profile-list__card">
                                    <a href="#"
                                        onClick={(e) => e.preventDefault()}
                                        // data-bs-toggle="modal" data-bs-target="#editEmailDrawer"
                                        >
                                        <div className="content">
                                            <span className="icon icon--sm icon--mail"></span>
                                            <h4 className="label label--medium label--3 card-title">
                                                {email}
                                            </h4>
                                        </div>
                                        {/* <span className="icon icon--sm icon--edit"></span> */}
                                    </a>
                                </li>
                                <li className="profile-list__card profile-list__card--logout">
                                    <a href="#" onClick={handleLogoutButton}>
                                        <div className="content">
                                            <span className="icon icon--sm icon--logout"></span>
                                            <h4 className="label label--medium label--3 card-title">Logg ut</h4>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <BottomBar />
            <EditEmailDrawer />
        </main>
    )
}

export default Profile