interface IDeleteModal {
    handleClick: Function
}

const DeleteConfirmModal = ({ handleClick }: IDeleteModal) => {
    return (
        <div className="modal fade modal--centered" id="confirmDeleteModal"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1} aria-labelledby="bottom-bar-add" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <header className="modal-header">
                        <button className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                            data-bs-toggle="modal"
                            data-bs-target="#addHourDrawer"
                            id="delete-confirm-close">
                            <span className="icon icon--sm icon--close"></span>
                        </button>
                    </header>
                    <div className="modal-body">
                        <h3 className="title title--h3">Slett registrering</h3>
                        <p className="body-text body-text--3">
                            Dette vil slette registreringen av timebruk
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button className="btn btn--outline"
                            data-bs-dismiss="modal"
                            data-bs-toggle="modal"
                            data-bs-target="#addHourDrawer">
                            Avbryt
                        </button>
                        <button className={`btn`}
                            onClick={() => { handleClick(); }}
                            id="confirmDeleteModal"
                            data-bs-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DeleteConfirmModal