const WorklogContainer = ({ children }: any) => {
    return (
        <div className="hour-list">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorklogContainer