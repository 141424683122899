import React from 'react';
import RootRouter from './components/RootRouter/RootRouter';
import { DaysProvider } from './services/DaysProvider/DaysProvider';
import { AuthProvider } from './services/AuthProvider/AuthProvider';
import { useEffect } from 'react';
import { PropertiesProvider } from './services/PropertiesProvider/PropertiesProvider';
import { ModalProvider } from './services/ModalProvider/ModalProvider';
import FakeSplashScreen from './components/FakeSplashScreen/FakeSplashScreen';
import { ConnectionProvider } from './services/ConnectionProvider/ConnectionProvider';
import OfflineBanner from './components/OfflineBanner/OfflineBanner';

function App() {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = "/assets/js/app.min.js";
    script.id = 'app-min';
    script.async = true;

    document.body.appendChild(script);

  }, []);

  return (
    <div>
      <ConnectionProvider>
        <AuthProvider>
          <OfflineBanner />
          <ModalProvider>
            <DaysProvider>
              <PropertiesProvider>
                <RootRouter />
              </PropertiesProvider>
            </DaysProvider>
          </ModalProvider>
        </AuthProvider>
      </ConnectionProvider>

      <FakeSplashScreen />
    </div>
  );
}

export default App;
