import React from 'react';
import { IMaskInput } from 'react-imask';

export enum HourInputTypes {
    Billable = 'billable',
    Work = 'work'
}
interface IHourInput {
    refValue: React.MutableRefObject<string>,
    inputRefValue: React.MutableRefObject<string>,
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    inputId: string,
    label: string,
    hourInputType: HourInputTypes,
    setSelectedHourInput: React.Dispatch<React.SetStateAction<string>>
}

const HourInput = ({ refValue, inputRefValue, value, setValue, inputId, label, setSelectedHourInput, hourInputType }: IHourInput) => {
    return (
        <div className="hour-input" onClick={() => setSelectedHourInput(hourInputType)}>
            <div className="hour-input__wrapper">
                <span className="icon icon--clock hour-input__icon"></span>
                <IMaskInput
                    // overwrite={true}
                    mask={"`0`0`:`M`m`"}
                    definitions={{
                        'M': /[0-5]/,
                        'm': /[0-9]/,
                    }}
                    lazy={false}
                    placeholderChar='0'
                    autofix={true}
                    ref={refValue}
                    // inputRef={inputRefValue}
                    defaultValue={value || "00:00"}
                    id={inputId}
                    name={inputId}
                    value={value}
                    autoComplete="false"
                    {...({ placeholder: "00:00" } as any)}
                    onAccept={(acceptedValue: string, mask) => {
                        setValue(acceptedValue);
                    }}
                    type="text"
                    {...({ 'data-drawer': "hourInputDrawer" } as any)}
                    {...({ className: "hour-input__input" } as any)}
                    {...({ 'inputMode': "none" } as any)}
                />
            </div>
            <label htmlFor={inputId} className="label label--4 hour-input__label">{label}</label>
        </div>
    )
}

export default HourInput