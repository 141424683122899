import DatepickerDrawer from "../DatepickerDrawer/DatepickerDrawer"
import React from 'react';

interface IDrawerHourInput {
    setBillableHoursValue: Function,
    setWorkHoursValue: Function,
    selectedHourInput: string,
}

const DrawerHourInput = ({ selectedHourInput, setBillableHoursValue, setWorkHoursValue }: IDrawerHourInput) => {
    const getClockValues = (numValues: number) => {
        return Array.from(Array(numValues).keys())
            .map((key) => {
                return <p className="label label--5" key={key}>
                    {key > 9 ? key : `0${key}`}
                </p>
            })
    }

    const handleHourSubmit = () => {
        if (selectedHourInput === 'billable') {
            setBillableHoursValue();
        } else {
            setWorkHoursValue();
        }
    }

    return (
        <>
            <div className="modal fade bottom-drawer" id="hourInputDrawer"
                tabIndex={-1}
                aria-hidden="true">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <header className="modal-header">
                                        <h4 className="label label--semibold label--4">Legg til timer</h4>
                                        <button className="btn-close" aria-label="Close">
                                            <span className="icon icon--sm icon--close"></span>
                                        </button>
                                    </header>
                                    <div className="modal-body">
                                        <div className="hour-slider" data-target-input="">
                                            <span className="hour-slider__middle-bar"></span>
                                            <div className="hour-slider__numbers hour-slider__numbers--hours">
                                                {getClockValues(24)}
                                            </div>
                                            <div className="hour-slider__numbers hour-slider__numbers--minutes">
                                                {getClockValues(60)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn btn--full btn-confirm" onClick={handleHourSubmit}>Bekreft</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <DatepickerDrawer /> */}
        </>

    )
}

export default DrawerHourInput