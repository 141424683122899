import { IWorkLog } from '../DaysProvider/DaysProvider';
import React from 'react';
import { createContext, useState } from 'react';

interface IModalProviderContext {
    selectedWorklog: IWorkLog | null,
    setSelectedWorklog: React.Dispatch<React.SetStateAction<IWorkLog | null>>,
    isEdit: boolean,
    setIsEdit: React.Dispatch<React.SetStateAction<boolean>>,
}

export const ModalProviderContext = createContext<IModalProviderContext>({
    selectedWorklog: null,
    setSelectedWorklog: () => null,
    isEdit: false,
    setIsEdit: () => null
});

export const ModalProvider = ({ children }: { children: any }) => {
    const [selectedWorklog, setSelectedWorklog] = useState<IWorkLog | null>(null);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    return <ModalProviderContext.Provider value={{
        selectedWorklog, setSelectedWorklog,
        isEdit, setIsEdit
    }}>
        {children}
    </ModalProviderContext.Provider>
}