import { HttpType, IRequest, LOCAL_STORAGE_MISSING_REQUESTS, processRequest, SUCCESS, IWorklogPayload } from '../services/ApiProvider/ApiProvider';
import { getStorage } from '../services/AuthProvider/AuthProvider';

export class MissingRequests {
    requests: Array<IRequest>;

    constructor() {
        const storage = getStorage();

        this.requests = JSON.parse(
            storage.getItem(LOCAL_STORAGE_MISSING_REQUESTS) || "[]"
        ) as Array<IRequest>;
    }

    handlePutRequests(request: IRequest): IRequest {
        const indexFirstTempRequest = this.requests.findIndex(
            (el) => el.payload.id == request.payload.id &&
                el.payload.withTempId == request.payload.withTempId
        );

        if (indexFirstTempRequest === -1) {
            return request;
        }

        const removedEl = this.requests.splice(indexFirstTempRequest, 1);

        if (removedEl[0] as IRequest && request.payload.withTempId) {
            request.payload.date = removedEl[0].payload.date;
        }

        if (request.payload.withTempId) {
            request.http = HttpType.POST;
            request.endpoint = '/worklog';
        }

        return request;
    }

    handleDeleteRequest(worklogId: number) {
        this.remove(worklogId);
    }

    add(request: IRequest) {
        // handle temporary ids made to deal with put requests for worklogs created offline
        if (request &&
            request.payload &&
            request.payload.id &&
            request.http === HttpType.PUT) {
            request = this.handlePutRequests(request);
        }

        if (request && request.http === HttpType.DELETE) {
            this.handleDeleteRequest(request.payload.id);
        }

        this.requests.push(request);
    }

    save() {
        const storage = getStorage();

        storage.setItem(
            LOCAL_STORAGE_MISSING_REQUESTS,
            JSON.stringify(this.requests)
        );
    }

    remove(worklogId: number) {
        const requestsList = [...this.requests];
        for (let i = 0; i < requestsList.length; i++) {
            let request = requestsList[i];
            if (request
                && request.payload
                && request.payload.id == worklogId) {
                this.requests.splice(i, 1);
            }
        }
    }

    getPayloads() {
        return this.requests
            .filter(request => request.http !== HttpType.DELETE)
            .map((request) => {
                return request.payload;
            }) as IWorklogPayload[];
    }

    async processAll() {
        const requestsList = [...this.requests];

        for (const request of requestsList) {

            if (request.http === HttpType.POST && request.payload.id) {
                delete request.payload.id;
            }

            const processResponse = await processRequest(request);

            if (processResponse.status === SUCCESS) {
                this.requests.shift();
                this.save();
            } else {
                return false;
            }
        }

        return true;
    }
}