import { useState, useEffect } from 'react';
import '../../index.css'
import { isPwa } from '../../services/Utils/Utils';

const DISPLAYED_SPLASH_SCREEN_KEY = 'OHT_SPLASH_SCREEN_DISPLAYED';

const FIVE_SECONDS = 1000 * 5;

const isIos = () => {
    const userAgent = navigator.userAgent;

    return (/iPad|iPhone|iPod/.test(userAgent))
        || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
}

const FakeSplashScreen = () => {
    const [show, setShow] = useState(false);
    const [animation, setAnimation] = useState(false);

    let classDiv = '';

    const style = {
        backgroundColor: '#e7e7ea',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        display: 'none'
    } as React.CSSProperties;

    if (show) {
        style.display = 'block';
    }

    if (animation) {
        classDiv = 'fade-out';
    }

    useEffect(() => {
        if (!isPwa()) {
            return;
        }

        if (isIos()) {
            return;
        }

        if (sessionStorage.getItem(DISPLAYED_SPLASH_SCREEN_KEY)) {
            return;
        }

        setShow(true);

        setTimeout(() => {
            sessionStorage.setItem(DISPLAYED_SPLASH_SCREEN_KEY, 'true');
            setAnimation(true);
        }, FIVE_SECONDS);
    }, []);

    return (
        <main className={`page-content ${classDiv}`} style={style}>
            <div className="splash-screen">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <figure className="splash-screen__figure">
                                <img src="/assets/images/splash-illustration.svg" alt="" />
                            </figure>
                            <div className="splash-screen__text">
                                <h1 className="title title--h1">Velkommen!</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default FakeSplashScreen