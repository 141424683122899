import { IWeekDay, DaysProviderContext } from '../../services/DaysProvider/DaysProvider';
import { useContext } from 'react';
import DayTab from '../DayTab/DayTab';
import HomepageSkeleton from '../HomepageSkeleton/HomepageSkeleton';

const DaysTabs = () => {
    const { weekDays, isLoading } = useContext(DaysProviderContext);

    return (
        <div className="tab-content">
            {isLoading ?
                <HomepageSkeleton />
                :
                weekDays.map((day, idx) => {
                    return <DayTab {...day} key={idx} />

                })}
        </div>
    )
}

export default DaysTabs