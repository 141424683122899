import { useController, useForm } from 'react-hook-form';
import React, { useEffect, useRef, useState, useContext } from 'react';
import ModalSelect from '../ModalSelect/ModalSelect';
import { PropertiesProviderContext, ICompany, IProject, ICategory, IOwner } from '../../services/PropertiesProvider/PropertiesProvider';
import DrawerHourInput from '../DrawerHourInput/DrawerHourInput';
import { ModalProviderContext } from '../../services/ModalProvider/ModalProvider';
import { DaysProviderContext } from '../../services/DaysProvider/DaysProvider';
import { IWorklogPayload, saveWorklog, SUCCESS, updateWorklog, NETWORK_ERROR, saveRequestWithoutNetwork, buildUpdateRequest, buildCreateRequest, deleteWorklog, buildDeleteRequest } from '../../services/ApiProvider/ApiProvider';
import { format, isEqual, isAfter, differenceInMonths, differenceInDays } from 'date-fns';
import { formatHourInputToMinutes, formatMinutesToHourInput, getSuggestions, SUGGESTION_KEYS, saveSuggestion, resetExternalLibs, getIntRandomBetween, isMonthBeforeAndIsAfterDayFive } from '../../services/Utils/Utils';
import { AuthProviderContext } from '../../services/AuthProvider/AuthProvider';
import { MissingRequests } from '../../entities/MissingRequests';
import { ConnectionProviderContext } from '../../services/ConnectionProvider/ConnectionProvider';
import { IMaskInput } from "react-imask";
import HourInput, { HourInputTypes } from '../HourInput/HourInput';
import DeleteButton from '../DeleteButton/DeleteButton';
import DeleteConfirmModal from '../DeleteConfirmModal/DeleteConfirmModal';
import BlockedAddMessage from '../BlockedAddMessage/BlockedAddMessage';
import nb from 'date-fns/locale/nb';

const AddWorklogModal = () => {
    const { handleSubmit, reset, trigger } = useForm<any>();
    const { companies, categories, projects, owners, findCategory, findCompany, findProject, findFilteredProject, findOwner } = useContext(PropertiesProviderContext);
    const { selectedWorklog, setSelectedWorklog, isEdit, } = useContext(ModalProviderContext);
    const { selectedDay, setWeekDays, weekDays, postId, setPostId } = useContext(DaysProviderContext);
    const { email, isObosUser } = useContext(AuthProviderContext);
    const { setIsOnline } = useContext(ConnectionProviderContext);
    const today = new Date();
    const dateToCompare = isEdit ? selectedWorklog && (new Date(selectedWorklog?.date)) || selectedDay : selectedDay;

    const isSelectedDateOnFuture = isAfter(dateToCompare, today);
    const isSelectedDateOnBlockedPast = isMonthBeforeAndIsAfterDayFive(today, dateToCompare);

    const [selectedHourInput, setSelectedHourInput] = useState('');

    const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);
    const companySuggestions = getSuggestions(`${email}_${SUGGESTION_KEYS.CompanySuggestions}`)
        .map(suggestion => findCompany(suggestion));

    const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(null);
    const categorySuggestions = getSuggestions(`${email}_${SUGGESTION_KEYS.CategorySuggestions}`)
        .map(suggestion => findCategory(suggestion));

    const [selectedProject, setSelectedProject] = useState<IProject | null>(null);
    const projectSuggestions = getSuggestions(`${email}_${SUGGESTION_KEYS.ProjectSuggestions}`)
        .map(suggestion => findProject(suggestion));

    const [filteredProjects, setFilteredProjects] = useState<IProject[]>
      (isObosUser ? projects : []);

    const [selectedOwner, setSelectedOwner] = useState<IOwner | null>(null);
    const [filteredOwners, setFilteredOwners] = useState<IOwner[]>([]);

    const [workMinutes, setWorkMinutes] = useState('00:00');
    const [billableMinutes, setBillableMinutes] = useState('00:00');

    const billableMinutesRef = useRef('00:00');
    const billableMinutesInputRef = useRef('00:00');

    const workMinutesRef = useRef('00:00');
    const workMinutesInputRef = useRef('00:00');

    const [description, setDescription] = useState('');

    let buttonSubmitClass = 'btn btn--full';

    const ESC_KEY = 'Escape';

    const setWorkHoursValue = () => {
        const minutes = formatHourInputToMinutes((document.querySelector('#hour-1') as HTMLInputElement).value)
        setWorkMinutes(formatMinutesToHourInput(minutes))
    }

    const setBillableHoursValue = () => {
        const minutes = formatHourInputToMinutes((document.querySelector('#hour-2') as HTMLInputElement).value)
        setBillableMinutes(formatMinutesToHourInput(minutes));
    }


    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setDescription(event.target.value);
    }

    const handleDeleteNetworkError = (worklogId: number) => {
        setIsOnline(false);
        let currentWeekDays = weekDays;

        currentWeekDays = currentWeekDays.map(
            (weekDay) => {
                weekDay.workLog = weekDay.
                    workLog?.filter(workLog => workLog.id !== worklogId) || [];
                return weekDay;
            }
        );

        setWeekDays([...currentWeekDays]);

        if (selectedWorklog?.withTempId) {
            const missingRequests = new MissingRequests();
            missingRequests.remove(selectedWorklog.id || 0);
            return missingRequests.save();
        }

        saveRequestWithoutNetwork(buildDeleteRequest(worklogId));
    }

    const handleSaveNetworkError = (payload: IWorklogPayload) => {
        setIsOnline(false);
        let currentWeekDays = weekDays;

        if (isEdit) {
            currentWeekDays = currentWeekDays.map(
                (weekday) => {
                    weekday.workLog = weekday.workLog?.map(
                        (worklog) => {
                            if (worklog.id == selectedWorklog?.id &&
                                worklog.withTempId == selectedWorklog?.withTempId) {
                                worklog = payload;
                            }
                            return worklog;
                        }) || [];
                    return weekday;
                }
            );
        } else {
            const tempId = getIntRandomBetween(1, 10000);
            payload.id = tempId;
            payload.withTempId = true;

            currentWeekDays = currentWeekDays
                .map(
                    (weekday) => {
                        if (
                            format(weekday.date, "yyyy-MM-dd") === payload.date.split('T')[0]
                        ) {
                            const weekdayWorklogs = weekday.workLog || [];
                            weekdayWorklogs.push(payload);

                            weekday.workLog = weekdayWorklogs;
                        }

                        return weekday;
                    }
                );
        }

        saveRequestWithoutNetwork(isEdit ? buildUpdateRequest(payload) :
            buildCreateRequest(payload));

        setWeekDays([...currentWeekDays]);
    }

    const onSubmit = async (form: any) => {
        const payload: IWorklogPayload = {
            date: format(selectedWorklog?.date && new Date(selectedWorklog.date) || selectedDay, "yyyy-MM-dd"),
            companyId: selectedCompany?.id?.toString(),
            categoryId: selectedCategory?.id?.toString(),
            projectId: selectedProject?.id?.toString(),
            ownerId: selectedOwner?.id?.toString() || null,
            workMinutes: formatHourInputToMinutes(workMinutes || ''),
            billableMinutes: formatHourInputToMinutes(billableMinutes || ''),
            description: description,
            withTempId: selectedWorklog?.withTempId ?? false
        }
        if (isEdit) {
            payload.id = selectedWorklog?.id;
            payload.date = selectedWorklog?.date.toString() || format(selectedDay, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
        }

        const saveResponse = isEdit ? await updateWorklog(payload) : await saveWorklog(payload);

        if (payload.categoryId) {
            saveSuggestion(`${email}_${SUGGESTION_KEYS.CategorySuggestions}`, payload.categoryId);
        }

        if (payload.companyId) {
            saveSuggestion(`${email}_${SUGGESTION_KEYS.CompanySuggestions}`, payload.companyId);
        }

        if (payload.projectId) {
            saveSuggestion(`${email}_${SUGGESTION_KEYS.ProjectSuggestions}`, payload.projectId);
        }
        if (saveResponse === SUCCESS) {
            const missingRequests = new MissingRequests();
            missingRequests.remove(payload.id || 0);
            missingRequests.save();
            setPostId(postId + 1);
        }

        // If network error, persist the new/editted worklogs on memory
        if (saveResponse === NETWORK_ERROR) {
            handleSaveNetworkError(payload);
        }

        setFilteredOwners([]);
        if (!isObosUser) {
          setFilteredProjects([]);
        }
        clearFormValues();
    }

    const throwDeleteWorklog = async () => {
        if (!selectedWorklog) {
            return;
        }

        const worklogId = selectedWorklog && selectedWorklog.id || 0;

        const deleteResponse = await deleteWorklog(worklogId);

        if (deleteResponse === NETWORK_ERROR) {
            return handleDeleteNetworkError(worklogId);
        }

        if (deleteResponse === SUCCESS) {
            setPostId(postId + 1);
        }

        setFilteredOwners([]);
        if (!isObosUser) {
          setFilteredProjects([]);
        }
        clearFormValues();
    }

    useEffect(() => {
        if (!isEdit) {
            clearFormValues();
        }

        resetExternalLibs();

    }, []);

    const clearFormValues = () => {
        reset();
        setDescription('');
        setSelectedCategory(null);
        setSelectedCompany(null);
        setSelectedProject(null);
        setWorkMinutes('');
        setBillableMinutes('');
    }

    const handleCompanyChange = (company: ICompany | null) => {
        if (!company) {
            return setSelectedOwner(null);
        }

        if (!isObosUser) {
          const companyProjects = 
            projects.filter(
              project => project.parentId && 
              company.parentId && 
              project.parentId === company.parentId
            );
          
          setFilteredProjects(companyProjects);

          if (!selectedWorklog || !selectedWorklog.projectId) {
            return setSelectedProject(null);
          }

          const filteredProject = findFilteredProject(selectedWorklog.projectId, companyProjects);

          if (!filteredProject) {
            setSelectedProject(null);
          }

          return setSelectedOwner(null);
        }


        const companyOwners = owners.filter(owner => owner.parentId && company.parentId && owner.parentId === company.parentId);

        setFilteredOwners(companyOwners);

        if (!selectedWorklog || !selectedWorklog.ownerId) {
            return setSelectedOwner(null);
        }

        const owner = findOwner(selectedWorklog.ownerId, companyOwners);

        if (!owner) {
            return setSelectedOwner(null);

        }

        setSelectedOwner(owner);
    }

    useEffect(() => {
        if (!isEdit) {
            setFilteredOwners([]);
            if (!isObosUser) {
              setFilteredProjects([]);
            }
            clearFormValues();
        }
    }, [isEdit]);

    useEffect(() => {
        handleCompanyChange(selectedCompany);
    }, [selectedCompany]);

    useEffect(() => {
        if (!selectedWorklog) {
            setFilteredOwners([]);
            setSelectedOwner(null);
            if (!isObosUser) {
              setFilteredProjects([]);
            }
            clearFormValues();
            return;
        }
        clearFormValues();

        setDescription(selectedWorklog.description)

        setWorkMinutes(formatMinutesToHourInput(selectedWorklog.workMinutes));

        setBillableMinutes(formatMinutesToHourInput(selectedWorklog.billableMinutes));

        if (selectedWorklog.companyId) {
            const selectedCompany = findCompany(selectedWorklog.companyId);
            if (selectedCompany) {
                setSelectedCompany(selectedCompany);
            }
        }

        if (selectedWorklog.categoryId) {
            const selectedCategory = findCategory(selectedWorklog.categoryId);
            if (selectedCategory) {
                setSelectedCategory(selectedCategory);
            }
        }

        if (selectedWorklog.projectId) {
            const selectedProject = findProject(selectedWorklog.projectId);
            if (selectedProject) {
                setSelectedProject(selectedProject);
            }
        }

        trigger();

    }, [selectedWorklog]);

    if (!description || !selectedCompany || !selectedCategory || workMinutes === '00:00') {
        buttonSubmitClass += ' disabled';
    }

    const handleEscKey = (keyEvent: React.KeyboardEvent<HTMLDivElement>) => {
        if (!isEdit) {
            return;
        }

        if (keyEvent.key === ESC_KEY) {
            clearFormValues();
        }
    }

    const handleCloseButton = () => {
        if (!isEdit) {
            return;
        }

        clearFormValues();
    }


    if (isSelectedDateOnFuture) {
        return <BlockedAddMessage header='Fremtidig rapportering'
            text='Det er ikke lov å legge inn timer på fremtidige datoer.' />
    }

    if (isSelectedDateOnBlockedPast) {
        return <BlockedAddMessage header='Utilgjengelige datoer'
            text='Det er ikke lov å legge inn timer på denne datoen fordi perioden er lukket.' />
    }

    const formattedDay = () => {
        const formatted =
            format((selectedWorklog?.date && new Date(selectedWorklog.date) || selectedDay),
                "EEEE d'.' LLL", { locale: nb })

        return formatted.charAt(0).toUpperCase() + formatted.slice(1);
    }

    return (
        <>
            <div className="modal fade bottom-drawer"
                id="addHourDrawer"
                tabIndex={-1}
                aria-hidden="true"
                data-bs-backdrop="static"
                onKeyDown={handleEscKey}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2">
                            <div className="modal-dialog">
                                <form onSubmit={handleSubmit(onSubmit)} className='modal-content'>
                                    <header className="modal-header">
                                        <h4 className="label label--semibold label--4">Registrer timer - {formattedDay()}</h4>
                                        <button className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={handleCloseButton}
                                            type='button'>
                                            <span className="icon icon--sm icon--close"></span>
                                        </button>
                                    </header>
                                    <div className="modal-body">

                                        <ModalSelect id='company'
                                            label='Selskap'
                                            defaultValue='Velg selskap'
                                            selectedValue={selectedCompany}
                                            setSelectedValue={setSelectedCompany}
                                            items={companies}
                                            suggestions={companySuggestions}
                                        />

                                        <ModalSelect id='project'
                                            label='Prosjekt'
                                            defaultValue='Velg prosjekt'
                                            selectedValue={selectedProject}
                                            setSelectedValue={setSelectedProject}
                                            items={filteredProjects}
                                        />

                                        {isObosUser ? <ModalSelect
                                            id='owner'
                                            label='Beboer'
                                            defaultValue='Velg beboer'
                                            selectedValue={selectedOwner}
                                            setSelectedValue={setSelectedOwner}
                                            items={filteredOwners}
                                        /> : null}

                                        <ModalSelect
                                            id='category'
                                            label='Kategori'
                                            defaultValue='Velg kategori'
                                            selectedValue={selectedCategory}
                                            setSelectedValue={setSelectedCategory}
                                            items={categories}
                                            suggestions={categorySuggestions}
                                        />

                                        <div className="input-group">
                                            <h4 className="label label--2 label--semibold">Legg til timer</h4>
                                            <HourInput
                                                refValue={workMinutesRef}
                                                inputRefValue={workMinutesInputRef}
                                                value={workMinutes}
                                                setValue={setWorkMinutes}
                                                inputId="hour-1"
                                                label='Totalt medgått tid'
                                                hourInputType={HourInputTypes.Work}
                                                setSelectedHourInput={setSelectedHourInput}
                                            />

                                            <HourInput
                                                refValue={billableMinutesRef}
                                                inputRefValue={billableMinutesInputRef}
                                                value={billableMinutes}
                                                setValue={setBillableMinutes}
                                                inputId="hour-2"
                                                label='Fakturerbart tid'
                                                hourInputType={HourInputTypes.Billable}
                                                setSelectedHourInput={setSelectedHourInput}
                                            />

                                        </div>
                                        <div className="input-group">
                                            {/* <!-- Please change the "for" and "id" attributes depending on context  --> */}
                                            <label htmlFor="beskrivelse">Beskrivelse (kommer på faktura)</label>
                                            <textarea rows={5}
                                                value={description}
                                                onChange={handleDescriptionChange}
                                                id="beskrivelse"
                                                placeholder="Skriv inn tekst her..."></textarea>
                                        </div>

                                        {isEdit ? <DeleteButton /> : null}
                                    </div>
                                    <div className="modal-footer">
                                        <button className={buttonSubmitClass} data-bs-dismiss="modal">
                                            Lagre
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DrawerHourInput
                setBillableHoursValue={setBillableHoursValue}
                setWorkHoursValue={setWorkHoursValue}
                selectedHourInput={selectedHourInput}
            />
            <DeleteConfirmModal handleClick={throwDeleteWorklog} />
        </>
    )
}

export default AddWorklogModal