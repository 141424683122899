import BottomBar from "../../components/BottomBar/BottomBar";
import DaysTabs from "../../components/DaysTabs/DaysTabs";
import WeekBar from "../../components/WeekBar/WeekBar";

export default function Home() {
    return (
        <main className="page-content">
            <WeekBar />
            <DaysTabs />
            <BottomBar />
        </main>
    )
}