import ReactDatePicker from 'react-datepicker';
import { useContext } from 'react';
import { DaysProviderContext } from '../../services/DaysProvider/DaysProvider';
import { format } from 'date-fns';
import nb from 'date-fns/locale/nb';
import { registerLocale } from 'react-datepicker';
import TodayButton from '../TodayButton/TodayButton';
import { formatWorklogTotal } from '../../services/Utils/Utils';
registerLocale('nb', nb)


interface IOverviewHeader {
    minutesTotal: number,
    handleDatepickerChange: any,
    isOnline: boolean
}

const OverviewHeader = ({ minutesTotal, handleDatepickerChange, isOnline }: IOverviewHeader) => {
    const { selectedWeek } = useContext(DaysProviderContext);

    return (
        <div className="overview-header" style={{ zIndex: 1000 }} id='overview-header'>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <div className="wrapper">
                            <h2 className="overview-header__text label label--4">
                                Totalt
                                <span className="label label--semibold">
                                    {` ${formatWorklogTotal(minutesTotal)}`} timer </span>
                                i  {format(selectedWeek, 'LLLL', { locale: nb })}</h2>
                            <div className='datepicker-container datepicker-container--mobile datepicker-container--month'>
                                <button className="btn btn--icon-left btn--datepicker"
                                    data-bs-toggle="modal"
                                    data-bs-target="#datepickerMonthDrawer">
                                    <span className="icon icon--calendar"></span>
                                    Velg måned
                                </button>
                            </div>
                            <div className='datepicker-container datepicker-container--desktop datepicker-container--month'>
                                <button
                                    className="btn btn--icon-left btn--datepicker"
                                    tabIndex={-1} aria-hidden='true'>
                                    <span className="icon icon--calendar"></span>
                                    Velg måned
                                </button>
                                <ReactDatePicker selected={selectedWeek}
                                    locale='nb'
                                    showMonthYearPicker
                                    onChange={handleDatepickerChange} />
                            </div>
                            <TodayButton />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OverviewHeader