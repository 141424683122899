import axios, { AxiosResponse } from "axios";
import { isLoginExpired, AuthProviderContext, removeLocalStorageValues, getStorage } from '../AuthProvider/AuthProvider';
import { format, startOfMonth, startOfWeek, endOfWeek } from 'date-fns';
import { subDays, addDays, endOfMonth } from 'date-fns/esm';
import MockedWorklogs from '../DaysProvider/MockedWorlogs';
import { PROPERTY_HASHS } from '../PropertiesProvider/PropertiesProvider';
import { MissingRequests } from '../../entities/MissingRequests';
import { getIntRandomBetween } from "../Utils/Utils";
import { IWorkLog } from '../DaysProvider/DaysProvider';

export const API_URL = process.env.REACT_APP_API_URL ? `${process.env.REACT_APP_API_URL}/api/v1` : 'http://localhost:8090/api/v1'

export const LOCAL_STORAGE_TOKEN_KEY = 'OHT_TOKEN';

export const LOCAL_STORAGE_EXPIRATION_KEY = 'OHT_EXPIRATION';

export const LOCAL_STORAGE_USER_EMAIL = 'OHT_EMAIL';

export const LOCAL_STORAGE_MISSING_REQUESTS = 'OHT_MISSING_REQUESTS';

export const SUCCESS = 200;

export const INTERNAL_SERVER_ERROR = 500;

export const UNAUTHORIZED = 401;

export const NETWORK_ERROR = 'ERR_NETWORK';

export const OVERVIEW_PAGE_SIZE = 500;
const MAX_PAGE_SIZE = 10000;

export const axiosInstance = axios.create({
    baseURL: API_URL
});

axiosInstance.interceptors.request.use((config) => {
    const token = getStorage().getItem(LOCAL_STORAGE_TOKEN_KEY);

    config.headers.Authorization = token ? `Bearer ${token}` : '';
    config.headers['Cache-Control'] = 'no-cache';
    config.headers['Pragma'] = 'no-cache';
    config.headers['Expires'] = '0';

    return config;
})

interface ILogin {
    email: string,
    hashCode: string,
    verificationCode: string
}

export enum HttpType {
    POST = 'POST',
    GET = 'GET',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

export interface IRequest {
    payload: any,
    endpoint: string,
    http: HttpType
}

export const login = async ({ email, hashCode, verificationCode }: ILogin) => {
    const response = await axiosInstance.post(`/authentication/login`, {
        email,
        hashCode,
        verificationCode
    });

    return response;
}

export const sendVerificationCode = async (email: string) => {
    const response = await axiosInstance.post(`/authentication/sendVerificationCode`,
        { Email: email }
    );

    return response;
}

type CallMethod = (...args: any[]) => any;


export const saveRequestWithoutNetwork = (request: IRequest) => {
    const missingRequests = new MissingRequests();
    missingRequests.add(request);
    missingRequests.save();
}

export const processRequest = (request: IRequest) => {
    if (request.http === HttpType.DELETE) {
        return axiosInstance.delete(request.endpoint);
    }
    return axiosInstance.request(
        {
            url: request.endpoint,
            method: request.http,
            data: request.payload
        }
    )
}

const getErrorReturn = (error: any, request: IRequest | null = null) => {
    if (error.code === NETWORK_ERROR &&
        request) {

        return NETWORK_ERROR;
    }

    if (!error || !error.response) {
        return [];
    }

    if (error.response.status === UNAUTHORIZED) {
        return null;
    }

    return error.response.status;
}

const withTokenExpirationValidation = (method: CallMethod) => (
    ...args: any[]
): ReturnType<typeof method> => {
    if (isLoginExpired()) {
        removeLocalStorageValues();
        return null;
    }

    return method(...args);
};

const getPropertyData = (key: string, response: any) => {
    const data = response[key] || null;

    if (!data) {
        return null;
    }

    getStorage().setItem(`OHT_${key.toUpperCase()}_HASH`, data.hashCode);
    return data.data;
}

export const getProperties = withTokenExpirationValidation(async () => {
    const storage = getStorage();

    const request: IRequest = {
        payload: {
            params: {
                hash: [
                    storage.getItem(PROPERTY_HASHS.Categories) || '',
                    storage.getItem(PROPERTY_HASHS.Companies) || '',
                    storage.getItem(PROPERTY_HASHS.Projects) || '',
                    storage.getItem(PROPERTY_HASHS.Owners) || ''
                ]
            },
            paramsSerializer: {
                indexes: null
            }
        },
        endpoint: '/property/sync',
        http: HttpType.GET
    }

    const propertiesResponse = await axiosInstance.get(request.endpoint,
        request.payload);

    if (!propertiesResponse || propertiesResponse.status !== SUCCESS) {
        return [null, null, null, null];
    }

    const responseData = propertiesResponse.data;

    return [getPropertyData('companies', responseData),
    getPropertyData('categories', responseData),
    getPropertyData('projects', responseData),
    getPropertyData('owners', responseData)];
});


export const getWorklogs = withTokenExpirationValidation(async (selectedDate: Date) => {
    const formatDate = (date: Date) => format(date, "yyyy-MM-dd");

    const request: IRequest = {
        payload: {
            params: {
                StartDate: formatDate(startOfWeek(selectedDate, { weekStartsOn: 1 })),
                EndDate: formatDate(endOfWeek(selectedDate, { weekStartsOn: 1 })),
                PageSize: MAX_PAGE_SIZE
            }
        },
        endpoint: '/worklog',
        http: HttpType.GET
    }

    try {
        const worklogResponse = await axiosInstance.get(request.endpoint, request.payload);
        return worklogResponse && worklogResponse.data?.data || []
    } catch (e) {
        console.log(e);
        return getErrorReturn(e, {} as IRequest);
    }
})
const formatDate = (date: Date) => format(date, "yyyy-MM-dd");

export const getMonthWorklogs = withTokenExpirationValidation(async (selectedMonth: Date, page: number = 1) => {

    const request: IRequest = {
        payload: {
            params: {
                StartDate: formatDate(startOfMonth(selectedMonth)),
                EndDate: formatDate(endOfMonth(selectedMonth)),
                PageSize: OVERVIEW_PAGE_SIZE,
                PageNumber: page
            }
        },
        endpoint: '/worklog',
        http: HttpType.GET
    }

    try {
        const worklogResponse = await axiosInstance.get(request.endpoint, request.payload);

        return {
            data: worklogResponse && worklogResponse.data?.data || [],
            minutesTotal: worklogResponse && worklogResponse.data?.minutesTotal || 0,
            countTotal: worklogResponse && worklogResponse.data?.countTotal || 1
        }
    } catch (e) {
        console.log(e);
        return getErrorReturn(e, {} as IRequest);
    }
});

export const buildCreateRequest = (worklog: IWorkLog): IRequest => {
    return {
        endpoint: '/worklog',
        payload: { ...worklog },
        http: HttpType.POST
    }
}

export const buildUpdateRequest = (worklog: IWorkLog): IRequest => {
    return {
        payload: { ...worklog },
        endpoint: `/worklog/${worklog.id}`,
        http: HttpType.PUT
    }
}

export const buildDeleteRequest = (worklogId: Number): IRequest => {
    return {
        payload: {
            id: worklogId
        },
        endpoint: `/worklog/${worklogId}`,
        http: HttpType.DELETE
    };
}

export const saveWorklog = withTokenExpirationValidation(async (worklog: IWorklogPayload) => {
    const request = buildCreateRequest(worklog);

    try {
        const saveResponse = await axiosInstance.post(request.endpoint, request.payload);
        return saveResponse.status;
    } catch (e) {
        console.log(e);
        return getErrorReturn(e, request);
    }
})

export const updateWorklog = withTokenExpirationValidation(async (worklog: IWorklogPayload) => {
    if (worklog.withTempId) {
        const newWorklog = { ...worklog };
        delete newWorklog.id;
        return saveWorklog(newWorklog);
    }

    const request = buildUpdateRequest(worklog);

    try {
        const updateResponse = await axiosInstance.put(request.endpoint, request.payload);
        return updateResponse.status;
    } catch (e) {
        console.log(e);
        return getErrorReturn(e, request);
    }
});

export const ping = () => {
    try {
        return axiosInstance.get('/verifyConnection');
    } catch (e) {
        return getErrorReturn(e, {} as IRequest);
    }
}

export const sendWorklogsConfirmation = withTokenExpirationValidation(async (year: number, month: number) => {
    const request: IRequest = {
        payload: {
            params: {
                Month: month,
                Year: year
            }
        },
        endpoint: '/worklog/confirm',
        http: HttpType.POST
    }

    try {
        const confirmationResponse = await axiosInstance.post(
            request.endpoint,
            null,
            request.payload);

        return confirmationResponse.status;
    } catch (e) {
        console.log(e);
        return getErrorReturn(e, request);
    }
})

export const deleteWorklog = withTokenExpirationValidation(async (worklogId: number) => {
    const request = buildDeleteRequest(worklogId);

    try {
        const deleteResponse = await axiosInstance.delete(request.endpoint);

        return deleteResponse.status;
    } catch (e) {
        console.log(e);
        return getErrorReturn(e, request);
    }
});

export interface IWorklogPayload {
    id?: number,
    date: string,
    companyId?: string,
    categoryId?: string,
    projectId?: string,
    ownerId?: string | null,
    workMinutes: number,
    billableMinutes: number,
    description: string,
    withTempId?: boolean,
    locked?: boolean
}
