

const DeleteButton = () => {
    const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
    }
    return (
        <div className="input-group">
            <a href="#" onClick={onClick}
                data-bs-toggle="modal"
                data-bs-target="#confirmDeleteModal"
                className="btn btn--outline btn--danger btn--icon-left btn--full">
                <span className="icon icon--trash"></span>
                Slett registrering
            </a>
        </div>
    )
}

export default DeleteButton