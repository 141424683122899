import { IWorkLog, IWeekDay } from '../DaysProvider/DaysProvider';
import { format } from 'date-fns';
import { generateArrayBetweenDates } from '../Utils/Utils';

export const formatDate = (date: Date) => {
    return format(date, 'yyyy-MM-dd');
}

const groupWorklogsByDate = (worklogs: IWorkLog[]) => {
    return worklogs.reduce((acc: any, obj) => {
        const formattedDate = typeof obj.date === 'string' ? obj.date.substring(0, 10) :
            formatDate(new Date(obj.date));
        if (acc[formattedDate]) {
            acc[formattedDate].push(obj);
        } else {
            acc[formattedDate] = [obj];
        }
        return acc;
    }, {});
}

export const buildWeekDays = (worklogs: IWorkLog[], startDate: Date, endDate: Date) => {
    const groupedWorkLogs = groupWorklogsByDate(worklogs);

    return generateArrayBetweenDates(startDate, endDate)
        .map((date) => {
            return {
                date,
                workLog: groupedWorkLogs[formatDate(date)] || []
            }
        }) as IWeekDay[];
}

export const fillWeekDays = (worklogs: IWorkLog[], weekDays: IWeekDay[]) => {
    const groupedWorklogs = groupWorklogsByDate(worklogs);
    
    return weekDays.map((weekDay) => {
        return {
            date: weekDay.date,
            workLog: [...(weekDay && weekDay.workLog || []), ...(groupedWorklogs[formatDate(weekDay.date)] || [])]
        }
    })
}