import { useContext } from 'react';
import { DaysProviderContext } from '../../services/DaysProvider/DaysProvider';
const TodayButton = () => {
    const { setSelectedWeek, setSelectedDay } = useContext(DaysProviderContext);
    
    const handleClick = () => {
        const today = new Date();
        setSelectedDay(today);
        setSelectedWeek(today);
    }

    return (
        <button className="btn btn--icon-left btn--datepicker btn--today"
            onClick={handleClick}>
            <span className="icon icon--home"></span>
            I dag
        </button>
    )

}

export default TodayButton