import { IWorkLog } from '../../services/DaysProvider/DaysProvider';
import { formatWorklogTotal } from '../../services/Utils/Utils';
import { useContext } from 'react';
import { ModalProviderContext } from '../../services/ModalProvider/ModalProvider';
import { PropertiesProviderContext } from '../../services/PropertiesProvider/PropertiesProvider';

const OverviewWorklog = (worklog: IWorkLog) => {
    const { setSelectedWorklog, setIsEdit } = useContext(ModalProviderContext);
    const { findCompany, findCategory } = useContext(PropertiesProviderContext);

    const selectedCompany = worklog.companyId ? findCompany(worklog.companyId)?.title : '';
    const selectCategory = worklog.categoryId ? findCategory(worklog.categoryId)?.title : '';

    const handleWorklogEdit = () => {
        setSelectedWorklog(worklog);
        setIsEdit(true);
    }

    let aClass = '';
    let iconClass = 'icon icon--sm icon--edit';

    if (worklog.locked) {
        aClass = 'locked';
        iconClass = 'icon icon--sm icon--lock';
    }

    return (
        <li key={worklog.id}>
            <a href='#'
                className={aClass}
                tabIndex={worklog.locked ? -1 : 1}
                onClick={(e) => { e.preventDefault(); handleWorklogEdit(); }}
                data-bs-target={worklog.locked ? '' : '#addHourDrawer'}
                data-bs-toggle={worklog.locked ? '' : 'modal'}>
                <div className="section-text">
                    <h4 className="label label--semibold label--3 section-title">
                        {selectedCompany}
                    </h4>
                    <p className="section-category text-gray-60">{selectCategory}</p>
                </div>
                <div className="section-hour">
                    <p className="label label--semibold label--4 text-green">
                        {formatWorklogTotal(worklog.workMinutes)}
                    </p>
                    <span className={iconClass}></span>
                </div>
            </a>
        </li>
    )
}

export default OverviewWorklog