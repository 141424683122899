import { useForm } from "react-hook-form";
import { useState, useEffect, useContext } from 'react';
import { AuthProviderContext, LOGIN_REMEMBER, getStorage } from '../../services/AuthProvider/AuthProvider';
import { login, SUCCESS, LOCAL_STORAGE_TOKEN_KEY, LOCAL_STORAGE_EXPIRATION_KEY, LOCAL_STORAGE_USER_EMAIL } from '../../services/ApiProvider/ApiProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { PropertiesProviderContext } from '../../services/PropertiesProvider/PropertiesProvider';
import { DaysProviderContext } from '../../services/DaysProvider/DaysProvider';
interface ILoginCode {
    email: string,
    hashCode: string,
    loginRemember: boolean
}

export default function LoginCode({ email, hashCode, loginRemember }: ILoginCode) {

    const { register, handleSubmit, formState, setValue, trigger, watch } = useForm();

    const { setEmail, setToken, setIsLogged, setExpiration } = useContext(AuthProviderContext);
    const { setSelectedDay, setSelectedWeek } = useContext(DaysProviderContext);
    const { throwGetProperties } = useContext(PropertiesProviderContext);

    const [error, setError] = useState(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const BACKSPACE = 'Backspace';
    const DELETE = 'Delete';

    const isNumeric = (str: any) => !isNaN(str);

    const isLetter = (str: string) => {
        return str.length === 1 && str.match(/[a-z]/i);
    };

    const handleCodeInput = (e: React.FormEvent<HTMLInputElement>) => {
        const formattedValue = e.currentTarget.value.replace(/[^0-9][^a-z]/g, '');
        e.currentTarget.value = formattedValue;
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>, key: number) => {
        if (error) {
            setError(false);
        }

        if ((e.key === BACKSPACE || e.key === DELETE) && key > 0) {
            e.stopPropagation();
            e.preventDefault();
            e.currentTarget.value = '';
            const previousSibling = document.querySelector(`input[name=n${key - 1}]`) as HTMLElement;
            if (previousSibling) {
                return setTimeout(() => {
                    previousSibling.focus();
                }, 50);
            }
        }

        if (key <= 5 && (isNumeric(e.key) || isLetter(e.key))) {
            // e.stopPropagation();
            // e.preventDefault();
            // e.currentTarget.value = e.key;
            const nextSibling = document.querySelector(`input[name=n${key + 1}]`) as HTMLElement;
            if (nextSibling) {
                return setTimeout(() => {
                    nextSibling.focus();
                }, 50);
            }

            trigger();
        }
    }

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const arrayOfWords = e.clipboardData.getData('Text').split('');
        for (let [index, word] of Array.from(arrayOfWords.entries())) {
            if (index <= 5) {
                setValue(`n${index}`, word);
            }
        }

        trigger();
    }

    const getVerificationCode = (form: any) => {
        return Array.from(Array(6).keys())
            .reduce((accumulator, currentValue) => {
                return accumulator + form[`n${currentValue}`];
            }, '');
    }

    const onSubmit = async (form: any) => {
        setIsLoading(true);

        try {
            const responseLogin = await login({ email, hashCode, verificationCode: getVerificationCode(form) });

            if (responseLogin.status === SUCCESS) {
                const token = responseLogin.data?.token;
                const expiration = responseLogin.data?.expiration;

                sessionStorage.setItem(`${LOGIN_REMEMBER}`, (loginRemember ? 'false' : 'true'));

                const storage = getStorage();

                storage.
                    setItem(LOCAL_STORAGE_TOKEN_KEY, token);
                storage.
                    setItem(LOCAL_STORAGE_EXPIRATION_KEY, expiration);
                storage.
                    setItem(LOCAL_STORAGE_USER_EMAIL, email);

                setSelectedDay(new Date());
                setSelectedWeek(new Date());
                setToken(token);
                setExpiration(new Date(expiration));
                setIsLogged(true);
                setEmail(email);

                throwGetProperties();
            };
        } catch (e: any) {
            console.log('error');
            setIsLoading(false);
            setError(true);
        }

    }

    let buttonClass = 'btn btn--filled btn--full';
    let inputClass = '';

    if (!formState.isValid) {
        buttonClass += ' disabled';
    }

    if (isLoading) {
        buttonClass += ' btn--loading'
    }

    if (error) {
        inputClass = 'error';
    }

    useEffect(() => {
        trigger();
    }, []);

    return (
        <>
            <main className="page-content"
                onPaste={handlePaste}>
                <section className="login login--code">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 offset-md-4 col-sm-8 offset-sm-2">
                                <h1 className="title title--h1">Skriv inn kode</h1>
                                <h2 className="label label--2">En engangskode har blitt sendt til mailen din</h2>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="code-inputs">
                                        {
                                            Array.from(Array(6).keys())
                                                .map((key) => {
                                                    return <input type="tel"
                                                        maxLength={1}
                                                        key={key}
                                                        className={inputClass}
                                                        onInput={handleCodeInput}
                                                        onKeyDown={(e) => handleKeyPress(e, key)}
                                                        required
                                                        {...register(`n${key}`, { required: true })}
                                                        name={`n${key}`}
                                                        aria-label="sms-number"
                                                        autoComplete="off" />
                                                })
                                        }
                                        {error ?
                                            <p className="input-error">Feil kode, prøv igjen</p> : ''}
                                    </div>
                                    <button id="loginCodeSubmit" className={buttonClass}
                                        type="submit">
                                        Logg inn
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </>

    )
}