import { Link, useLocation } from "react-router-dom"
import AddWorklogModal from "../AddWorklogModal/AddWorklogModal"
import { useContext } from 'react';
import { ModalProviderContext } from '../../services/ModalProvider/ModalProvider';
import { DaysProviderContext } from '../../services/DaysProvider/DaysProvider';

const BottomBar = ({ isOverview }: { isOverview?: boolean }) => {
    const { setIsEdit, setSelectedWorklog } = useContext(ModalProviderContext);
    const { setSelectedDay, setSelectedWeek } = useContext(DaysProviderContext);
    let overviewClass = '';
    if (isOverview) {
        overviewClass += 'active';
    }

    const location = useLocation();
    const isActive = (page: string) => {
        return page === location.pathname ? 'active' : ''
    }

    return (
        <>
            <nav className="bottom-bar">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 offset-md-2 col-sm-10 offset-sm-1">
                            <ul>
                                <li className={isActive('/')} onClick={() => {
                                    const today = new Date();
                                    setSelectedDay(today);
                                    setSelectedWeek(today);
                                }}>
                                    <Link to="/" style={{ textDecoration: 'none' }}
                                        id="bottom-bar-home" className="bottom-bar__link">
                                        <span className="icon icon--home"></span>
                                        <p className="label label--2">Hjem</p>
                                    </Link>

                                </li>
                                <li className={`${isActive('/overview')} ${overviewClass}`}
                                    onClick={() => {
                                        const today = new Date();
                                        setSelectedDay(today);
                                        setSelectedWeek(today);
                                    }}>
                                    <Link to="/overview" style={{ textDecoration: 'none' }}
                                        id="bottom-bar-overview" className="bottom-bar__link">
                                        <span className="icon icon--calendar"></span>
                                        <p className="label label--2">Oversikt</p>
                                    </Link>
                                </li>
                                <li>
                                    <button id="#"
                                        className="bottom-bar__button-add"
                                        onClick={() => {
                                            setIsEdit(false);
                                            setSelectedWorklog(null);
                                        }}
                                        aria-label="Add Hours"
                                        data-bs-toggle="modal"
                                        data-bs-target="#addHourDrawer">
                                        <span className="icon icon--plus"></span>
                                    </button>
                                </li>
                                <li className={isActive('/sync')}>
                                    <Link to="/sync" style={{ textDecoration: 'none' }}
                                        id="bottom-bar-sync" className="bottom-bar__link">
                                        <span className="icon icon--refresh"></span>
                                        <p className="label label--2">Synk</p>
                                    </Link>
                                </li>
                                <li className={isActive('/profile')}>
                                    <Link to="/profile" style={{ textDecoration: 'none' }}
                                        id="bottom-bar-profile" className="bottom-bar__link">
                                        <span className="icon icon--user"></span>
                                        <p className="label label--2">Min profil</p>
                                    </Link>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            <AddWorklogModal />
        </>
    )
}

export default BottomBar