import { IWeekDay, DaysProviderContext, IWorkLog } from '../../services/DaysProvider/DaysProvider';
import { format } from 'date-fns'
import nb from 'date-fns/locale/nb';
import { useContext } from 'react';
import isSelectedDay, { formatWorklogTotal } from '../../services/Utils/Utils';

const WeekDay = (day: IWeekDay) => {
    const norwegianDay = format(day.date, 'eee', { locale: nb });
    const englishDay = format(day.date, 'eeee').toLowerCase();
    const dayNumber = parseInt(format(day.date, 'dd'));
    const { selectedDay, setSelectedDay } = useContext(DaysProviderContext);

    const isSelected = isSelectedDay(day.date, selectedDay);

    const handleDateChange = () => {
        setSelectedDay(day.date);
    }

    const totalWorklogMinutes = day.workLog ?
        day.workLog.reduce((accumulator, workLog) => {
            return accumulator + workLog.workMinutes
        }, 0) : 0;

    const totalWorkLogHours = formatWorklogTotal(totalWorklogMinutes);

    let hoursClass = 'label label--2';

    if (totalWorklogMinutes) {
        hoursClass += ' text-green'
    }

        return (
            <li role='presentation' key={dayNumber}>
                <button
                    className={isSelected ? 'active' : ''}  
                    type='button'
                    role='tab'
                    onClick={handleDateChange}
                    aria-controls={`${englishDay}`}
                    aria-selected={isSelected}
                    id={`${englishDay}-tab`}
                    // data-bs-toggle='tab'
                    data-bs-target={`#${englishDay}`}>
                    <span className='label label--2'>
                        {norwegianDay.charAt(0).toUpperCase() + norwegianDay.slice(1)}
                    </span>
                    <span className='label label--4'>
                        {dayNumber}
                    </span>
                    <span className={hoursClass}>
                        {totalWorkLogHours}
                    </span>
                </button>
            </li>
        )
}

export default WeekDay