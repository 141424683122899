import React, { createContext, useRef, useState } from 'react';
import MockedDays from './MockedDays';
import { getWorklogs, NETWORK_ERROR } from '../ApiProvider/ApiProvider';
import { useContext, useEffect } from 'react';
import { AuthProviderContext } from '../AuthProvider/AuthProvider';
import { buildWeekDays } from '../WorklogsProvider/WorklogsProvider';
import { subDays, addDays } from 'date-fns/esm';
import { endOfWeek, startOfWeek } from 'date-fns';
import { ConnectionProviderContext } from '../ConnectionProvider/ConnectionProvider';
import { MissingRequests } from '../../entities/MissingRequests';
import { ModalProviderContext } from '../ModalProvider/ModalProvider';
export interface IWorkLog {
    id?: number,
    date: Date | string,
    workMinutes: number,
    billableMinutes: number,
    categoryId?: string,
    projectId?: string,
    ownerId?: string | null,
    companyId?: string,
    description: string,
    withTempId?: boolean,
    locked?: boolean
}

export interface IWeekDay {
    date: Date,
    workLog?: IWorkLog[]
}

export interface DayWorklog {
    company: string,
    category: string,
    totalHours: string
}

type IDaysProviderContext = {
    weekDays: IWeekDay[],
    setWeekDays: React.Dispatch<React.SetStateAction<IWeekDay[]>>,
    selectedDay: Date,
    setSelectedDay: React.Dispatch<React.SetStateAction<Date>>,
    selectedWeek: Date,
    setSelectedWeek: React.Dispatch<React.SetStateAction<Date>>,
    postId: number,
    setPostId: React.Dispatch<React.SetStateAction<number>>,
    isLoading: boolean,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
};

export const DaysProviderContext = createContext<IDaysProviderContext>({
    weekDays: [],
    setWeekDays: () => null,
    selectedDay: new Date(),
    setSelectedDay: () => null,
    selectedWeek: new Date(),
    setSelectedWeek: () => null,
    postId: 0,
    setPostId: () => null,
    isLoading: false,
    setIsLoading: () => null
});

export const DaysProvider = ({ children }: { children: any }) => {
    const [weekDays, setWeekDays] = useState<IWeekDay[]>([]);
    const [postId, setPostId] = useState(0);
    const { isLogged, setIsLogged, logout } = useContext(AuthProviderContext);
    const [selectedDay, setSelectedDay] = useState<Date>(new Date);
    const [selectedWeek, setSelectedWeek] = useState<Date>(new Date);
    const [isLoading, setIsLoading] = useState(false);

    const { isOnline, setIsOnline } = useContext(ConnectionProviderContext);

    const { setSelectedWorklog } = useContext(ModalProviderContext);

    const isOnlineRef = useRef(isOnline);

    const throwGetWeekDays = async (day: Date) => {
        if (!isLogged) {
            return;
        }

        setIsLoading(true);

        let responseWorklogs = await getWorklogs(day);

        if (!responseWorklogs) {
            return logout();
        }

        if (responseWorklogs === NETWORK_ERROR) {
            const missingRequests = new MissingRequests();
            responseWorklogs = missingRequests.getPayloads();
            setIsOnline(false);
        } else {
            setIsOnline(true);
        }

        const weekDaysOutput = buildWeekDays(
            responseWorklogs,
            startOfWeek(day, { weekStartsOn: 1 }),
            endOfWeek(day, { weekStartsOn: 1 })
        );

        setWeekDays(weekDaysOutput);

        setSelectedWorklog(null);

        setIsLoading(false);
    }

    const throwProcessMissingRequests = async () => {
        try {
            const missingRequests = new MissingRequests();

            if (!missingRequests.requests.length) {
                return;
            }

            const isRequestsProcessed = await missingRequests.processAll();

            if (isRequestsProcessed) {
                setPostId(postId + 1);
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        const missingRequests = new MissingRequests();

        if (missingRequests.requests.length
            && isOnline
            && isOnlineRef.current) {
            throwProcessMissingRequests();
        }

        try {
            throwGetWeekDays(selectedWeek);
        } catch (e) {
            console.log('error useEffect week ---------- \n');
            console.log(e);
        }

    }, [isLogged, postId, selectedWeek]);

    useEffect(() => {
        if (!isOnlineRef.current && isOnline) {
            throwProcessMissingRequests();
        }


        isOnlineRef.current = isOnline;
    }, [isOnline]);

    return <DaysProviderContext.Provider value={{
        weekDays, setWeekDays,
        selectedDay, setSelectedDay,
        selectedWeek, setSelectedWeek,
        postId, setPostId,
        isLoading, setIsLoading
    }}>
        {children}
    </DaysProviderContext.Provider>
}