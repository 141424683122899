import { useState, createContext, useEffect } from 'react';
import axios from 'axios';
import { NETWORK_ERROR, ping } from '../ApiProvider/ApiProvider';

type IConnectionProviderContext = {
    isOnline: boolean,
    setIsOnline: React.Dispatch<React.SetStateAction<boolean>>,
}

export const ConnectionProviderContext = createContext<IConnectionProviderContext>({
    isOnline: true,
    setIsOnline: () => null
});

const REQUEST_INTERVAL = 1000 * 30; // 30 seconds

export const ConnectionProvider = ({ children }: { children: any }) => {
    const [isOnline, setIsOnline] = useState(true);

    const setOnline = () => setIsOnline(true);
    const setOffline = () => setIsOnline(false);

    useEffect(() => {
        const intervalId = setInterval(async () => {
            if (isOnline) {
                return;
            }

            try {
                const pingResponse = await ping();

                if (pingResponse) {
                    setOnline();
                    return;
                }
            } catch (e: any) {
                console.log(e);

                if (e.code === NETWORK_ERROR) {
                    setOffline();
                }
            }

        }, REQUEST_INTERVAL);

        return () => clearInterval(intervalId);
    }, [isOnline]);

    return <ConnectionProviderContext.Provider value={{ isOnline, setIsOnline }}>
        {children}
    </ConnectionProviderContext.Provider>
}