import { IWeekDay } from '../../services/DaysProvider/DaysProvider';
import { format } from 'date-fns';
import nb from 'date-fns/locale/nb';
import { formatWorklogTotal } from '../../services/Utils/Utils';
import OverviewWorklog from '../OverviewWorklog/OverviewWorklog';
import { formatDate } from '../../services/WorklogsProvider/WorklogsProvider';
import { useRef, useEffect } from 'react';

const OverviewDay = ({ date, workLog }: IWeekDay) => {
    const dayRef = useRef(null);
    
    const totalWorkLogHours = formatWorklogTotal(workLog ?
        workLog.reduce((accumulator, workLog) => {
            return accumulator + workLog.workMinutes
        }, 0) : 0);

    const dateString = format(date, "EEEE d'.'LLLL", { locale: nb })
    const formattedDate = formatDate(date);

    useEffect(() => {
        if (formattedDate === formatDate(new Date())) {
            let rect = (dayRef.current as any);
            if (!rect) {
              return;
            }
            const headerOffset = document.getElementById('overview-header')?.getBoundingClientRect().height || 0;
            rect = rect.getBoundingClientRect();
            window.scrollTo({ top: rect.top + window.pageYOffset - headerOffset, behavior: 'smooth' })
        }
    }, []);

    const days = <section className='overview-list__section'
        id={`worklogs-${formattedDate}`} ref={dayRef}>
        <header>
            <h3 className='label label--2'>{dateString.charAt(0).toUpperCase() + dateString.slice(1)}</h3>
            <h3 className='label label--2'>{totalWorkLogHours}t</h3>
        </header>
        <ul>
            {workLog?.map((day) => {
                return <OverviewWorklog {...day} key={day.id} />
            })}
        </ul>
    </section>;

    return <>
        {totalWorkLogHours == '00:00' ? null : days}
    </>
}

export default OverviewDay