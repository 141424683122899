import { useForm } from "react-hook-form";
import { useEffect, useContext } from 'react';
import { AuthProviderContext } from '../../services/AuthProvider/AuthProvider';

const EditEmailDrawer = () => {
    const { register, setValue, handleSubmit } = useForm();

    const { email } = useContext(AuthProviderContext);

    const onSubmit = (form: any) => {
        
    }

    useEffect(() => {
        setValue('email', email);
    }, []);

    return (
        <div className="modal fade bottom-drawer" id="editEmailDrawer" tabIndex={-1} aria-hidden="true">
            <form className="container" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <header className="modal-header">
                                    <h4 className="label label--semibold label--4">Endre e-post</h4>
                                    <button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                        <span className="icon icon--sm icon--close"></span>
                                    </button>
                                </header>
                                <div className="modal-body">
                                    <div className="input-group">
                                        <label htmlFor="editEmailInput">E-post</label>
                                        <input type="email" id="editEmailInput"
                                            {...register('email')} />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn--full" data-bs-dismiss="modal">Lagre</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
export default EditEmailDrawer