import BottomBar from "../../components/BottomBar/BottomBar"
import { useEffect, useContext, useState } from 'react';
import { AuthProviderContext } from '../../services/AuthProvider/AuthProvider';
import { getMonthWorklogs, NETWORK_ERROR, OVERVIEW_PAGE_SIZE } from '../../services/ApiProvider/ApiProvider';
import { IWeekDay, DaysProviderContext } from '../../services/DaysProvider/DaysProvider';
import { buildWeekDays, fillWeekDays } from '../../services/WorklogsProvider/WorklogsProvider';
import { startOfMonth, format } from 'date-fns';
import { endOfMonth } from 'date-fns/esm';
import OverviewDay from "../../components/OverviewDay/OverviewDay";
import ReactDatePicker from "react-datepicker";
import DatepickerDrawer from "../../components/DatepickerDrawer/DatepickerDrawer";
import nb from 'date-fns/locale/nb';
import OverviewSubmitModal from "../../components/OverviewSubmitModal/OverviewSubmitModal";
import InfiniteScroll from 'react-infinite-scroll-component';
import { registerLocale } from 'react-datepicker';
import OverviewHeader from "../../components/OverviewHeader/OverviewHeader";
import ContentLoader from 'react-content-loader';
import OverviewContainer from "../../components/OverviewContainer/OverviewContainer";
import OverviewSkeleton from "../../components/OverviewSkeleton/OverviewSkeleton";
import { ConnectionProviderContext } from '../../services/ConnectionProvider/ConnectionProvider';
import OfflineOverview from "../../components/OfflineOverview/OfflineOverview";
registerLocale('nb', nb)

const Overview = () => {

    const { isLogged, setIsLogged, logout } = useContext(AuthProviderContext);

    const [page, setPage] = useState(1);

    const [maxPage, setMaxPage] = useState(1);

    const [hasMore, setHasMore] = useState(true);

    const { selectedWeek, setSelectedWeek, setSelectedDay, postId, setIsLoading, isLoading } = useContext(DaysProviderContext);

    const [monthWorklogs, setMonthWorklogs] = useState<IWeekDay[]>([]);

    const [minutesTotal, setMinutesTotal] = useState(0);

    const { isOnline, setIsOnline } = useContext(ConnectionProviderContext);

    const handleDatepickerChange = (date: Date) => {
        setSelectedWeek(date);
        // setSelectedDay(date);
    }

    const throwGetMonthWorklogs = async (month: Date, page: number = 1) => {
        if (!isLogged) {
            return;
        }

        if (page === 1) {
            setIsLoading(true);
        }

        if (maxPage < page && page !== 1) {
            return;
        }

        const responseWorklogs = await getMonthWorklogs(month, page);

        if (!responseWorklogs) {
            return logout();
        }

        if (responseWorklogs === NETWORK_ERROR) {
            setIsOnline(false);
        } else {
            setIsOnline(true);
        }

        if (!responseWorklogs.data) {
            setIsLoading(false);
            return;
        }

        if (responseWorklogs.countTotal) {
            const calcMaxPage = parseInt(responseWorklogs.countTotal) / OVERVIEW_PAGE_SIZE;
            setMaxPage(Math.ceil(calcMaxPage));
        }

        if (!responseWorklogs.data.length && page > 1) {
            setIsLoading(false);
            return setHasMore(false);
        }

        if (!hasMore) {
            setHasMore(true);
        }

        if (page > 1) {
            setIsLoading(false);
            return setMonthWorklogs(fillWeekDays(responseWorklogs.data, monthWorklogs))
        }

        const monthWorklogsOutput = buildWeekDays(
            responseWorklogs.data,
            startOfMonth(month),
            endOfMonth(month)
        );

        setMinutesTotal(responseWorklogs.minutesTotal);

        setMonthWorklogs(monthWorklogsOutput);

        setIsLoading(false);
    }

    useEffect(() => {
        document.getElementById('app-min')?.remove();

        const script = document.createElement('script');

        script.src = "/assets/js/app.min.js";
        script.id = 'app-min';
        script.async = true;

        document.body.appendChild(script);

        // throwGetMonthWorklogs(selectedWeek);

    }, []);

    useEffect(() => {
        throwGetMonthWorklogs(selectedWeek, 1);

        if (page !== 1) {
            setPage(1);
            setHasMore(true);
        }
    }, [postId, selectedWeek]);

    useEffect(() => {
        if (page === 1) {
            return;
        }

        throwGetMonthWorklogs(selectedWeek, page);
    }, [page]);

    const handleScroll = () => {
        if (hasMore) {
            setPage(page + 1);
        }
    }

    return (
        <main className="page-content">

            <OverviewHeader
                handleDatepickerChange={handleDatepickerChange}
                isOnline={isOnline}
                minutesTotal={minutesTotal} />
            <InfiniteScroll
                next={handleScroll}
                loader={<></>}
                hasMore={hasMore}
                dataLength={page * OVERVIEW_PAGE_SIZE}>
                {isOnline ?
                    <>
                        <div className="overview-list">
                            {isLoading ?
                                <OverviewContainer>
                                    <OverviewSkeleton />
                                </OverviewContainer>
                                :
                                <>
                                    <OverviewContainer>

                                        {monthWorklogs.map((day, idx) => {
                                            return <OverviewDay key={idx}
                                                date={day.date} workLog={day.workLog} />
                                        })}
                                    </OverviewContainer>

                                    <button className="btn overview-list__send" data-bs-toggle="modal"
                                        data-bs-target="#submitHourModal">Send inn timer</button>
                                </>

                            }
                        </div>

                    </>
                    : <OfflineOverview />}
            </InfiniteScroll>

            <BottomBar isOverview={true} />
            <DatepickerDrawer isMonthly={true} />
            <OverviewSubmitModal />
        </main >

    )
}

export default Overview