import { IWorkLog } from '../../services/DaysProvider/DaysProvider';
import WorklogItem from '../WorklogItem/WorklogItem';

const TabWorkLog = ({ worklogs }: { worklogs: IWorkLog[] }) => {
    return (
        <>
            <header className="hour-list__header">
                <h3 className="label label--semibold label--4">Dine timer</h3>
            </header>
            <ul className="hour-list__list">
                {worklogs.map((worklog, idx) => {
                    return <WorklogItem {...worklog} key={idx} />
                })}

            </ul>
        </>
    )
}

export default TabWorkLog 