
import { useContext, useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { DaysProviderContext } from '../../services/DaysProvider/DaysProvider';
import "react-datepicker/dist/react-datepicker.css";
import React from 'react';
import { isAfter, endOfWeek, isBefore, startOfWeek } from 'date-fns';
import { registerLocale } from 'react-datepicker';
import nb from 'date-fns/locale/nb';

interface IDatepickerDrawer {
    isMonthly?: boolean
}

registerLocale('nb', nb)

const DatepickerDrawer = ({ isMonthly }: IDatepickerDrawer) => {

    const [isOpen, setIsOpen] = useState(true)

    const { selectedDay, setSelectedDay, selectedWeek, setSelectedWeek } = useContext(DaysProviderContext);

    const [startDate, setStartDate] = useState(selectedDay);

    let drawerId = isMonthly ? 'datepickerMonthDrawer' : 'datepickerDrawer';

    const handleDatepickerChange = (date: Date) => {
        setStartDate(date)
    }

    const handleDrawerSubmit = () => {
        if (isMonthly) {
            setSelectedDay(startDate);
            return setSelectedWeek(startDate)
        }

        if (isAfter(startDate, endOfWeek(selectedDay, { weekStartsOn: 1 })) ||
            isBefore(startDate, startOfWeek(selectedDay, { weekStartsOn: 1 }))) {
            setSelectedWeek(startDate);
        }

        setSelectedDay(startDate);
    }

    useEffect(() => { setStartDate(selectedDay) }, [selectedDay]);

    useEffect(() => { setStartDate(selectedWeek) }, [selectedWeek]);

    return (
        <div className="modal fade bottom-drawer bottom-drawer--datepicker"
            id={drawerId} tabIndex={-1} aria-hidden="true">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <header className="modal-header">
                                    <h4 className="label label--semibold label--4">
                                        {isMonthly ? "Velg måned" : "Velg dag"}
                                    </h4>
                                    <button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                                        <span className="icon icon--sm icon--close"></span>
                                    </button>
                                </header>
                                <div className="modal-body">
                                    {isMonthly ? <ReactDatePicker
                                        selected={startDate}
                                        onChange={handleDatepickerChange}
                                        open={isOpen}
                                        shouldCloseOnSelect={false}
                                        showMonthYearPicker
                                        locale='nb'
                                        startOpen
                                    /> : <ReactDatePicker
                                        selected={startDate}
                                        onChange={handleDatepickerChange}
                                        open={isOpen}
                                        shouldCloseOnSelect={false}
                                        locale='nb'
                                        startOpen
                                    />}
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn--full"
                                        data-bs-dismiss="modal" id="applyDatepickerDate"
                                        onClick={handleDrawerSubmit}>Bekreft</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DatepickerDrawer