import { IWeekDay, DaysProviderContext } from '../../services/DaysProvider/DaysProvider';
import { format } from 'date-fns';
import WorklogOverview from '../WorklogItem/WorklogItem';
import { useContext } from 'react';
import isSelectedDay from '../../services/Utils/Utils';
import TabWorkLog from './TabWorkLog';
import TabEmpty from './TabEmpty';
import WorklogContainer from '../WorklogContainer/WorklogContainer';

const DayTab = (day: IWeekDay) => {
    const englishDay = format(day.date, 'eeee').toLowerCase();

    const { selectedDay } = useContext(DaysProviderContext);

    const isSelected = isSelectedDay(day.date, selectedDay);

    const tabClass = `tab-pane fade ${isSelected ? 'active show' : ''}`
    return (
        <div className={tabClass}
            id={englishDay}
            role='tabpanel'
            aria-labelledby={`${englishDay}-tab`}>
            <WorklogContainer>
                {
                    day.workLog && day.workLog.length ?
                        <TabWorkLog worklogs={day.workLog} />
                        :
                        <TabEmpty />
                }
            </WorklogContainer>
        </div>
    )
}

export default DayTab 