import { IWorkLog, } from '../../services/DaysProvider/DaysProvider';
import { formatWorklogTotal } from '../../services/Utils/Utils';
import { useContext } from 'react';
import { ModalProviderContext } from '../../services/ModalProvider/ModalProvider';
import { PropertiesProviderContext } from '../../services/PropertiesProvider/PropertiesProvider';

const WorklogItem = (worklog: IWorkLog) => {
    const { setSelectedWorklog, setIsEdit } = useContext(ModalProviderContext);
    const { findCompany, findCategory } = useContext(PropertiesProviderContext);

    const selectedCompany = worklog.companyId ? findCompany(worklog.companyId)?.title : '';
    const selectedCategory = worklog.categoryId ? findCategory(worklog.categoryId)?.title : '';

    const handleWorklogEdit = () => {
        setSelectedWorklog(worklog);
        setIsEdit(true);
    }
    
    let liClassname = 'hour-list__card';
    let iconClass = 'icon icon--sm icon--edit'

    if (worklog.locked) {
        liClassname += ' hour-list__card--locked'
        iconClass = 'icon icon--lock';
    }

    return (
        <li className={liClassname} key={JSON.stringify(worklog)}>
            <a href="#" onClick={(e) => { e.preventDefault(); handleWorklogEdit();}}
                data-bs-target={worklog.locked ? '' : '#addHourDrawer'}
                data-bs-toggle={worklog.locked ? '' : 'modal'}>
                <div className="content">
                    <h4 className="label label--semibold label--3 card-title">
                        {selectedCompany}
                    </h4>
                    <p className="label label--2 text-gray-60 card-category">
                        {selectedCategory}
                    </p>
                </div>
                <div className="hour">
                    <span className="icon icon--clock"></span>
                    <p className="label label--semibold label--4">
                        {formatWorklogTotal(worklog.workMinutes)}
                    </p>
                    <span className={iconClass}></span>
                </div>
            </a>
        </li>
    )
}

export default WorklogItem