import { format, intervalToDuration, isSameMonth, isAfter, differenceInCalendarMonths } from 'date-fns';
import { eachDayOfInterval } from 'date-fns/esm';
import { getStorage } from '../AuthProvider/AuthProvider';

export const suggestionsArray = [1, 2, 3];

export enum SUGGESTION_KEYS {
    CompanySuggestions = 'COMPANY_SUGGESTION',
    CategorySuggestions = 'CATEGORY_SUGGESTION',
    ProjectSuggestions = 'PROJECT_SUGGESTION'
}

const isSelectedDay = (weekDay: Date, selectedDay: Date) => {
    return format(weekDay, 'dd-MM-yyyy') === format(selectedDay, 'dd-MM-yyyy')
}

export const formatWorklogTotal = (durationInMinutes: number) => {
    const duration = intervalToDuration({
        start: 0,
        end: (durationInMinutes) * 1000
    });

    const zeroPad = (num: number | undefined) => String(num).padStart(2, '0');

    return `${durationInMinutes < 0 ? '-' : ''}${zeroPad(duration.minutes)}:${zeroPad(duration.seconds)}`;
}

export const formatHourInputToMinutes = (hourInput: string) => {
    if (!hourInput || hourInput === '' || hourInput === '-') {
        return 0;
    }

    const hourMinutesArray = hourInput.split(':').map(input => parseInt(input));

    if (hourMinutesArray.length === 1) {
        hourMinutesArray[1] = 0;
    }

    const [hours, minutes] = hourMinutesArray;
    const totalMinutes = (Math.abs(hours) * 60) + Math.abs(minutes);

    if (hourInput.indexOf('-') > -1) {
        return -totalMinutes;
    }

    return totalMinutes;
}

export const formatMinutesToHourInput = (minutes: number) => {
    if (!minutes) {
        return '';
    }

    const absoluteMinutes = Math.abs(minutes);
    const hours = Math.floor(absoluteMinutes / 60);
    const min = absoluteMinutes % 60;
    return `${minutes < 0 ? '-' : ''}${hours < 10 ? '0' + hours : hours}:${min < 10 ? '0' + min : min}`;
}

export const generateArrayBetweenDates = (startDate: Date, endDate: Date) => {
    return eachDayOfInterval({
        start: startDate,
        end: endDate
    })
}

export const isMonthBeforeAndIsAfterDayFive = (
    currentDay: Date,
    selectedDay: Date
) => {

    if (isAfter(selectedDay, currentDay)) {
        return false;
    }

    if (isSameMonth(currentDay, selectedDay)) {
        return false;
    }

    if (differenceInCalendarMonths(currentDay, selectedDay) >= 2) {
        return true;
    }

    if (parseInt(format(currentDay, 'd')) >= 5){
        return true;
    }

    return false;
}

export const saveSuggestion = (key: string, value: string) => {
    const suggestions = getSuggestions(key);
    const indexOfSuggestion = suggestions.indexOf(value);

    if (indexOfSuggestion !== -1) {
        suggestions.splice(indexOfSuggestion, 1);
        suggestions.unshift(value);
    } else {
        suggestions.unshift(value);
        if (suggestions.length > 3) {
            suggestions.pop();
        }
    }

    suggestions.forEach((suggestion, idx) => {
        getStorage().setItem(`${key}_${idx + 1}`, suggestion);
    })
}

export const getSuggestions = (key: string) => {
    const suggestions = [];
    for (let suggestion of suggestionsArray) {
        const suggestionItem = getStorage().getItem(`${key}_${suggestion}`);
        if (suggestionItem) {
            suggestions.push(suggestionItem);
        }
    }

    return suggestions;
}

export const resetExternalLibs = () => {
    document.getElementById('app-min')?.remove();
    const script = document.createElement('script');

    script.src = "/assets/js/app.min.js";
    script.id = 'app-min';
    script.async = true;

    document.body.appendChild(script);
}

export const getIntRandomBetween = (min: number, max: number) => {
    min = Math.ceil(min);
    max = Math.floor(max);

    return Math.floor(Math.random() * (max - min) + min);
}

export const isPwa = () => {
    return (window.matchMedia('(display-mode: standalone)').matches);
}

export default isSelectedDay