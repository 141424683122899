import { useContext, useEffect, useState } from 'react';
import WeekDay from '../WeekDay/WeekDay';
import WeekDays from '../WeekDays/WeekDays';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import nb from 'date-fns/locale/nb';
import { IWeekDay, DaysProviderContext } from '../../services/DaysProvider/DaysProvider';
import DatepickerDrawer from '../DatepickerDrawer/DatepickerDrawer';
import { PropertiesProviderContext } from '../../services/PropertiesProvider/PropertiesProvider';
import { WeekSlider } from '../WeekSlider/WeekSlider';
import { isAfter, endOfWeek, isBefore, startOfWeek } from 'date-fns';
import ContentLoader from "react-content-loader"
import TodayButton from '../TodayButton/TodayButton';

registerLocale('nb', nb)


const WeekBar = () => {
    const handleDatepickerChange = (date: Date) => {
        if (isAfter(date, endOfWeek(selectedDay, { weekStartsOn: 1 })) ||
            isBefore(date, startOfWeek(selectedDay, { weekStartsOn: 1 }))) {
            setSelectedWeek(date);
        }
        setSelectedDay(date)
    }

    const { weekDays, selectedDay, setSelectedDay, setSelectedWeek, isLoading } = useContext(DaysProviderContext);

    return (
        <>
            <div className="week-bar">
                <div className="container">
                    <div className="row">
                        <>
                            <div className="col-md-8 offset-md-2">

                                <div className="week-bar__week-selection">
                                    <WeekSlider />
                                    <div className='datepicker-container datepicker-container--mobile'>
                                        <button className="btn btn--icon-left btn--datepicker" data-bs-toggle="modal" data-bs-target="#datepickerDrawer">
                                            <span className="icon icon--calendar"></span>
                                            Velg dag
                                        </button>
                                    </div>
                                    <div className='datepicker-container datepicker-container--desktop'>
                                        <button className="btn btn--icon-left btn--datepicker" tabIndex={-1} aria-hidden='true'>
                                            <span className="icon icon--calendar"></span>
                                            Velg dag
                                        </button>
                                        <ReactDatePicker selected={selectedDay}
                                            locale='nb'
                                            onChange={handleDatepickerChange} />
                                    </div>
                                   <TodayButton />
                                </div>
                                <WeekDays>
                                    {weekDays}
                                </WeekDays>
                            </div>
                        </>
                    </div>
                </div>
            </div>
            <DatepickerDrawer />
        </>
    )
}

export default WeekBar