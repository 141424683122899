const OfflineOverview = () => {
    return (
        <div className="sync-data">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 offset-md-4 col-sm-8 offset-sm-2">
                        <p className="body-text body-text--3">
                        Du er ikke koblet til internett. 
                        Venligst sjekk din tilgang og prøv igjen.
                        </p>
                    </div>
                </div></div>
        </div>
    )
}

export default OfflineOverview