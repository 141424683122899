const OverviewContainer = ({ children }: any) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default OverviewContainer